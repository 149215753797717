import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';
import { useState, useEffect } from 'react';
import InputAdornment from '@mui/material/InputAdornment'; // Added
import IconButton from '@mui/material/IconButton'; // Added
import VisibilityOff from '@mui/icons-material/VisibilityOff'; // Added
import Visibility from '@mui/icons-material/Visibility'; // Added
const emblem = require('../main/images/initial.png');

const API_URL = process.env.REACT_APP_API_ORIGIN;

// TODO remove, this demo shouldn't need to reset the theme.

export default function SignUp() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [emailCheckMessage, setEmailCheckMessage] = useState('');
  const [idCheckMessage, setIdCheckMessage] = useState('');
  const [isFormValid, setIsFormValid] = useState(false); // 추가된 부분
  const [isEmailValid, setIsEmailValid] = useState(false); // 추가된 부분
  const [isPasswordValid, setIsPasswordValid] = useState(false); // 추가된 부분
  const [isIdValid, setIsIdValid] = useState(false); // 추가된 부분
  const [enteredPasswordInput, setEnteredPasswordInput] = useState(false);
  const [passwordCheckMessage, setPasswordCheckMessage] = useState('');
  const [isPhoneValid, setIsPhoneValid] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_URL}/consultant/getUserList`);
        const data = await response.json();
        setUserData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const isValidEmailFormat = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPasswordFormat = (password) => {
    // 비밀번호는 영문, 숫자, 특수문자 조합의 8자리 이상
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-_&])(?=.*[0-9]).{8,25}$/;
    return passwordRegex.test(password);
  };

  const handleEmailCheck = (enteredEmail) => {
    const isEmailValidFormat = isValidEmailFormat(enteredEmail);

    if (!isEmailValidFormat) {
      setEmailCheckMessage('올바른 이메일 형식이 아닙니다.');
      setIsEmailValid(false);
      return;
    }

    // userData가 null인 경우 초기화
    const isEmailDuplicate = userData ? userData.some((user) => user.email === enteredEmail) : false;

    if (isEmailDuplicate) {
      setEmailCheckMessage('중복된 이메일입니다. 다른 이메일을 입력해주세요.');
      setIsEmailValid(false);
    } else {
      setEmailCheckMessage('사용 가능한 이메일입니다.');
      setIsEmailValid(true);
    }
  };

  const handlePasswordChange = (enteredPassword) => {
    const isPasswordValidFormat = isValidPasswordFormat(enteredPassword);
    setEnteredPasswordInput(true);
    if (!enteredPassword || enteredPassword=='' || !isPasswordValidFormat) {
      console.log("password false");
      setPasswordCheckMessage('비밀번호 형식을 확인해주십시오.');
      setIsPasswordValid(false);
    } else {
      console.log("password true");
      setPasswordCheckMessage('사용 가능한 비밀번호입니다.');
      setIsPasswordValid(true);
    }
  };

  useEffect(() => {
    console.log("isformValid",isFormValid);
    if(isEmailValid && isPasswordValid && isIdValid){
      setIsFormValid(true);
    }
    else{
      setIsFormValid(false);
    }
  },[isEmailValid, isPasswordValid, isIdValid]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const enteredEmail = data.get('email');
    const enteredPassword = data.get('password');
    console.log(data.get('program'));

    handleEmailCheck(enteredEmail);
    handlePasswordChange(enteredPassword);

    if (emailCheckMessage === '사용 가능한 이메일입니다.' && isValidPasswordFormat(enteredPassword)) {
      const user = {
        name: data.get('Name'),
        email: enteredEmail,
        password: enteredPassword,
        usertype: 'client',
        phone_number: data.get('Phonenumber'),
        program: data.get('program'),
        nickname: data.get('nickname')
      };

      try {
        const response = await Axios.post(`${API_URL}/auth/join`, user, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        console.log(response.data.id);
        navigate('/Email', { state: { name: user.name, email: user.email, usertype: user.usertype, phone_number: user.phone_number, program: user.program, nickname: user.nickname, usersub: response.data.id} });
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleIdCheck = (enteredId) => {
    if (!enteredId) {
      return; // ID가 입력되지 않은 경우 중복 체크하지 않음
    }
  
    const isIdDuplicate = userData ? userData.some((user) => user.username === enteredId) : false;
  
    if (isIdDuplicate) {
      setIdCheckMessage('중복된 ID입니다. 다른 ID를 입력해주세요.');
      setIsIdValid(false);
    } else {
      setIdCheckMessage('사용 가능한 ID입니다.');
      setIsIdValid(true);
    }
  };

  return (
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={emblem} alt="Emblem" style={{ maxWidth: '90%', height: 'auto' }} />
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="given-name"
                  name="nickname"
                  required
                  fullWidth
                  id="nickname"
                  label="성명"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoComplete="given-name"
                  name="Name"
                  required
                  fullWidth
                  id="Name"
                  label="ID"
                  onChange={(e) => handleIdCheck(e.target.value)}
                />
                <Typography variant="body2" color={idCheckMessage.includes('중복') ? 'error' : 'primary'}>
                  {idCheckMessage}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="이메일"
                  name="email"
                  autoComplete="email"
                  onChange={(e) => handleEmailCheck(e.target.value)}
                />
                <Typography variant="body2" color={emailCheckMessage.includes('중복') ? 'error' : 'primary'}>
                  {emailCheckMessage}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="비밀번호"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="new-password"
                  onChange={(e) => handlePasswordChange(e.target.value)} // 추가된 부분
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={togglePasswordVisibility}
                          onMouseDown={(e) => e.preventDefault()} // 클릭 시 input 포커스 방지
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                {enteredPasswordInput && <Typography variant="body2" color={!isPasswordValid ? 'error' : 'primary'}>
                  {passwordCheckMessage}
                </Typography>
                }
                <Typography variant="body2" color="textSecondary" style={{ fontSize: '0.8rem', marginTop: '4px' }}>
                  영문, 숫자, 특수문자(!@#$%^*+=-_&) 조합의 8자리 이상의 비밀번호로 구성해 주십시오.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="Phonenumber"
                  label="전화번호"
                  type="Phonenumber"
                  id="Phonenumber"
                />
                <Typography variant="body2" color="textSecondary" style={{ fontSize: '0.8rem', marginTop: '4px' }}>
                  - 제외한 숫자만 입력해 주십시오.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="program" // Name 필드를 Program으로 변경
                  label="선호 화상회의 플랫폼"
                  select
                  SelectProps={{
                    native: true,
                  }}
                >
                  <option value="zoom">Zoom</option>
                  <option value="googleMeet">Google Meet</option>
                  <option value="webex">Webex</option>
                </TextField>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={!isFormValid}
            >
              회원가입
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item sx={{mb: 4 }}>
                <Link component={RouterLink} to="/SignIn" variant="body2">
                  이미 계정이 있으신가요? 로그인 하러 가기
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
  );
}