import React, { useState, useEffect } from 'react';
import { Box, CssBaseline, ThemeProvider, Typography, Divider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Navbar from './Navbar/Navbar';
import LeftNavbar from './Navbar/LeftNavbar';
import Toolbar from '@mui/material/Toolbar';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';

const InsightDetail = () => {
    const [open, setOpen] = useState(true);
    const navigate = useNavigate();

    const toggleDrawer = () => {
        setOpen(!open);
    };

    
    const defaultTheme = createTheme();

    return (
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Navbar open={open} toggleDrawer={toggleDrawer} />
                <LeftNavbar open={open} toggleDrawer={toggleDrawer} />
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        height: '100vh',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3} style={{ height: '100%' }}>
                            {/* 상단에 FACT 단계 제목 */}
                            <Grid item xs={12}>
                                <Typography variant="h5" align="left">
                                    INSIGHT 단계
                                </Typography>
                                <Divider />
                            </Grid>

                            {/* 가로로 반으로 나뉘어진 섹션 */}
                            <Grid item xs={6}>
                                {/* 첫 번째 섹션의 텍스트 문단 */}
                                <Typography variant="body1" align="left" style={{ marginBottom: '48px' }}>
                                    창업자가 사업 런칭 후 1~2년 동안 공략해야 할 SOM 시장을 도출합니다.
                                </Typography>
                                <Typography variant="body1" align="left" style={{ marginBottom: '48px' }}>
                                    SOM 시장에서는 구체적인 고객 특성을 분석하고, 이들 고객을 상대로 직접 경쟁하게 될 경쟁자들을 찾아내고 그 강약점을 분석합니다.
                                </Typography>
                                <Typography variant="body1" align="left" style={{ marginBottom: '48px' }}>
                                    SOM 시장 전체의 규모를 추정하여 향후 1~2년간 창업자가 달성할 수 있는 현실적 매출규모를 추정합니다.
                                </Typography>
                            </Grid>

                            <Grid item xs={6}>
                                {/* 두 번째 섹션의 텍스트 문단 */}
                                <Typography variant="body1" align="left" style={{ marginBottom: '48px' }}>
                                    <strong>GOAL</strong><br />
                                    ● 1~2년 내 집중해야 할 SOM 시장 정의<br />
                                    ● 사업성장기반 확보에 필요한 SOM 시장 추론<br />
                                </Typography>
                                <Typography variant="body1" align="left" style={{ marginBottom: '48px' }}>
                                    <strong>STEPS</strong><br />
                                    ● 사업유형별로 WHO - WHAT - HOW 관점의 상세한 질의 및 응답<br />
                                    ● SOM시장 정의 및 시장 규모 추론<br />
                                    ● 3~4개의 SOM 시장 옵션에 대한 평가 및 선정<br />
                                </Typography>
                                <Typography variant="body1" align="left" style={{ marginBottom: '48px' }}>
                                    <strong>OUTCOMES</strong><br />
                                    ● SOM 시장 정의 / 규모<br />
                                    ● 목표 고객 상세 특성<br />
                                    ● 직접 경쟁자 강약점 분석<br />
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* 버튼 추가 */}
                        <Grid item xs={12} style={{ marginTop: '24px', textAlign: 'center' }}>
                            <button onClick={() => navigate('/MainInsight')} style={{ fontSize: '12px', padding: '8px 36px' }}>
                                다 음
                            </button>
                        </Grid>

                        {/* 하단 설명 */}
                        <Grid item xs={12} style={{ marginTop: '48px' }}>
                            <Typography variant="body2" align="left">
                                <strong>SOM (Serviceable Obtainable Market):</strong> 목표 수익 시장으로 6개월, 1년, 2년 안에 도달할 1차적인 시장 점유를 목표로 하는 시장입니다.
                            </Typography>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default InsightDetail;
