import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SignIn from './certification/SignIn';
import SignUp from './certification/SignUp';
import Email from './certification/Email';
import MainCustomer from './main/Customer/MainCustomer';
import MainConsultant from './main/Consultant/MainConsultant'
import AuthToken from './certification/AuthToken';
import AuthLogin from './certification/AuthLogin';
import Resetpw from './certification/ResetPW';
import Forgotpw from './certification/ForgotPW';
import Agree from './certification/Agree';
import Home from './main/Customer/Home';
import Initial from './main/Initial';
import MainInsight from './main/Customer/MainInsight';
import MainStrategy from './main/Customer/MainStrategy';
import ConsultantConsulting  from './main/Consultant/Consulting';
import ConsultantMypage from './main/Consultant/Mypage';
import ConsultantUser from './main/Consultant/Users';
import ConsultantQuestion from './main/Consultant/Questions';
import AskGpt from './main/Consultant/AskGpt';
import FactDetail from './main/Customer/FactDetail';
import InsightDetail from './main/Customer/InsightDetail';
import StrategyDetail from './main/Customer/StrategyDetail';
import ActionDetail from './main/Customer/ActionDetail';
import ConsultingTable from './main/Consultant/ConsultingTable';
import AdminUser from './main/Admin/AdminUser';
import AdminMatching from './main/Admin/AdminMatching';
import AdminConsulting from './main/Admin/AdminConsulting';
import ConsultingEdit from './main/Admin/ConsultingEdit';
import FactReport from './main/Customer/FactReport';
import Consultantlist from './main/Customer/Consultantlist';
import UserMypage from './main/Customer/Mypage';
import FactFile from './main/Customer/FactFile';
import ConsultantFile from './main/Consultant/ConsultantFile';
import StrategyGtm from './main/Customer/StrategyGtm';
import PlatformGtmCanvas1 from './main/Customer/PlatformGtmCanvas1';
import ConsultantDiagnosis from './main/Consultant/ConsultantDiagnosis';
import ChangeQuestion from './main/Consultant/ChangeQuestion';
import ComparePersona from './main/Customer/StrategyComparePersona';
import InsightFile from './main/Customer/InsightFile';
import InsightTable from './main/Consultant/InsightTable';
import PlatformGtmCanvas2 from './main/Customer/PlatformGtmCanvas2';
import ChatwithGpt from './main/Consultant/ChatwithGpt';
import EvaluatePersona from './main/Customer/StrategyEvaluatePersona';
import CreatePersona from './main/Consultant/InsightCreatePersona';
import InsightReport from './main/Customer/InsightReport';
import StrategyReport from './main/Customer/StrategyReport';
import TamSam from './main/Consultant/TamSam';
import TamSamInsight from './main/Consultant/TamSamInsight';
import MainAdmin from './main/Admin/MainAdmin';
import UserList from './main/Admin/UserList';
import FilteredUser from './main/Admin/AdminFilteredUser';
import ProgressUser from './main/Admin/ProgressUser';
import ProgressConsultant from './main/Admin/ProgressConsultant';
import StrategyFile from './main/Customer/StrategyFile';
import BackgroundInfo from './main/Customer/BackgroundInfo';
import PromptEditor from './main/Admin/PromptEdit';
import ConsultantFact1 from './main/Consultant/ConsultantFact1';
import ConsultantFact2 from './main/Consultant/ConsultantFact2';
import AdminFilteredMatching from './main/Admin/AdminFilteredMatching.js';
import MatchingMain from './main/Admin/AdminMatchingMain.js';
import FilteredConsulting from './main/Admin/AdminFilteredConsulting.js';
import ConsultingMain from './main/Admin/AdminConsultingMain.js';
import StrategySom from './main/Consultant/StrategySom.js';

function App() {
  return (
    <Router>
      <Routes>
        {/* 항상 인가 */}
        <Route path="/SignIn" element={<SignIn />} />
        <Route path="/SignUp" element={<SignUp />} />
        <Route path="/" element={<Initial />} />
        <Route path="/Email" element={<Email />} />
        <Route path="/Reset" element={<Resetpw />} />
        <Route path="/Forgot" element={<Forgotpw />} />
        <Route path="/Agree" element={<Agree />} />
        <Route path='/Initial' element={<Initial />} />
        <Route path='/ProgressUser/:status' element={<ProgressUser />} />
        <Route path='/ProgressConsultant/:status' element={<ProgressConsultant />} />
        <Route path='/ConsultantFact1' element={<ConsultantFact1 />} />
        <Route path='/ConsultantFact2' element={<ConsultantFact2 />} />
        {/* admin 인가 */}
        <Route path='/ProgressUser/:status' element={<AuthLogin requiredType="admin"><ProgressUser /></AuthLogin>}/>
        <Route path='/ProgressConsultant/:status' element={<AuthLogin requiredType="admin"><ProgressConsultant /></AuthLogin>}/>
        <Route path='/AdminUser' element={<AuthLogin requiredType="admin"><AdminUser/></AuthLogin>}/>
        <Route path='/AdminMatching' element={<AuthLogin requiredType="admin"><AdminMatching/></AuthLogin>}/>
        <Route path='/AdminConsulting' element={<AuthLogin requiredType="admin"><AdminConsulting/></AuthLogin>}/>
        <Route path='/MainAdmin' element={<AuthLogin requiredType="admin"><MainAdmin/></AuthLogin>}/>
        <Route path='/Users/:groupId' element={<AuthLogin requiredType="admin"><UserList/></AuthLogin>}/>
        <Route path='/FilteredUser/:groupId/:groupName' element={<AuthLogin requiredType="admin"><FilteredUser/></AuthLogin>}/>
        <Route path='/ConsultingEdit' element={<AuthLogin requiredType="admin"><ConsultingEdit/></AuthLogin>}/>
        <Route path='/PromptEditor' element={<AuthLogin requiredType="admin"><PromptEditor/></AuthLogin>}/>
        <Route path='/MatchingMain' element={<AuthLogin requiredType="admin"><MatchingMain/></AuthLogin>}/>
        <Route path='/ConsultingMain' element={<AuthLogin requiredType="admin"><ConsultingMain/></AuthLogin>}/>
        <Route path='/FilteredMatching/:groupId/:groupName' element={<AuthLogin requiredType="admin"><AdminFilteredMatching/></AuthLogin>}/>
        <Route path='/FilteredConsulting/:groupId/:groupName' element={<AuthLogin requiredType="admin"><FilteredConsulting/></AuthLogin>}/>
        {/* client 인가 */}
        <Route path="/Home" element={<AuthLogin requiredType="client"><Home /></AuthLogin>}/>
        <Route path="/MainCustomer" element={<AuthLogin requiredType="client"><MainCustomer /></AuthLogin>}/>
        <Route path="/MainInsight" element={<AuthLogin requiredType="client"><MainInsight /></AuthLogin>}/>
        <Route path="/MainStrategy" element={<AuthLogin requiredType="client"><MainStrategy /></AuthLogin>}/>    
        <Route path='/FactDetail' element={<AuthLogin requiredType="client"><FactDetail /></AuthLogin>}/>
        <Route path='/InsightDetail' element={<AuthLogin requiredType="client"><InsightDetail/></AuthLogin>}/>
        <Route path='/StrategyDetail' element={<AuthLogin requiredType="client"><StrategyDetail/></AuthLogin>}/>
        <Route path='/ActionDetail' element={<AuthLogin requiredType="client"><ActionDetail/></AuthLogin>}/>
        <Route path='/ConsultantList' element={<AuthLogin requiredType="client"><Consultantlist/></AuthLogin>}/>
        <Route path='/UserMypage' element={<AuthLogin requiredType="client"><UserMypage/></AuthLogin>}/>
        <Route path='/FactFile/:uid' element={<AuthLogin requiredType="client,consultant"><FactFile/></AuthLogin>}/>
        <Route path='/InsightFile/:uid' element={<AuthLogin requiredType="client,consultant"><InsightFile/></AuthLogin>}/>
        <Route path='/StrategyFile/:uid' element={<AuthLogin requiredType="client,consultant"><StrategyFile/></AuthLogin>}/>
        <Route path='/FactReport' element={<AuthLogin requiredType="client"><FactReport/></AuthLogin>}/>
        <Route path='/InsightReport' element={<AuthLogin requiredType="client"><InsightReport/></AuthLogin>}/>
        <Route path='/BackInfo' element={<AuthLogin requiredType="client"><BackgroundInfo/></AuthLogin>}/>
        <Route path='/StrategyReport' element={<AuthLogin requiredType="client"><StrategyReport/></AuthLogin>}/>

        {/* consulatant 인가 */}
        <Route path="/MainConsultant" element={<AuthLogin requiredType="consultant"><MainConsultant /></AuthLogin>}/>
        <Route path="/ConsultantMypage" element = {<AuthLogin requiredType="consultant"><ConsultantMypage/></AuthLogin>}/>
        <Route path="/ConsultantUsers" element = {<AuthLogin requiredType="consultant"><ConsultantUser/></AuthLogin>}/>
        <Route path="/ConsultantConsulting" element = {<AuthLogin requiredType="consultant"><ConsultantConsulting/></AuthLogin>}/>
        <Route path="/ConsultantQuestions" element = {<AuthLogin requiredType="consultant"><ConsultantQuestion/></AuthLogin>}/>
        <Route path='/AskGpt' element={<AuthLogin requiredType="consultant"><AskGpt /></AuthLogin>} />
        <Route path='/ConsultingTable' element={<AuthLogin requiredType="consultant"><ConsultingTable/></AuthLogin>}/>
        <Route path='/ConsultantFile' element={<AuthLogin requiredType="consultant"><ConsultantFile/></AuthLogin>}/>
        <Route path='/ConsultantDiagnosis' element={<AuthLogin requiredType="consultant"><ConsultantDiagnosis/></AuthLogin>}/>
        <Route path='/ChangeQuestion' element={<AuthLogin requiredType="consultant"><ChangeQuestion/></AuthLogin>}/>
        <Route path='/InsightTable' element={<AuthLogin requiredType="consultant"><InsightTable/></AuthLogin>}/>
        <Route path='/ChatwithGpt' element={<AuthLogin requiredType="consultant"><ChatwithGpt/></AuthLogin>}/>
        <Route path='/CreatePersona' element={<AuthLogin requiredType="consultant"><CreatePersona/></AuthLogin>}/>
        <Route path='/TamSam' element={<AuthLogin requiredType="consultant"><TamSam/></AuthLogin>}/>
        <Route path='/TamSamInsight' element={<AuthLogin requiredType="consultant"><TamSamInsight/></AuthLogin>}/>

        {/* client, consultant 중복 인가 */}
        <Route path='/StrategyGtm' element={<AuthLogin requiredType="consultant,client"><StrategyGtm/></AuthLogin>}/>
        <Route path='/PlatformGtmCanvas1' element={<AuthLogin requiredType="consultant,client"><PlatformGtmCanvas1/></AuthLogin>}/>
        <Route path='/ComparePersona' element={<AuthLogin requiredType="consultant,client"><ComparePersona/></AuthLogin>}/>
        <Route path="/EvaluatePersona" element={<AuthLogin requiredType="consultant,client"><EvaluatePersona/></AuthLogin>}/>
        <Route path='/PlatformGtmCanvas2' element={<AuthLogin requiredType="consultant,client"><PlatformGtmCanvas2/></AuthLogin>}/>
        <Route path='/StrategySom' element={<AuthLogin requiredType="consultant, client"><StrategySom/></AuthLogin>}/>

      </Routes>
    </Router>
  );
}

export default App;
