import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import HelpIcon from '@mui/icons-material/Help';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SummarizeIcon from '@mui/icons-material/Summarize';
import Window from '../../Customer/Window/Window';
import FactManual from '../FactManual';
import ChatwithGpt from '../ChatwithGpt';
import InsightManual from '../InsightManual';
import TamSamManual from '../TamSamManual';
import SomManual from '../SomManual';
import GtmManual from '../GtmManual';

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const DrawerComponent = ({ open, toggleDrawer, initialMessages = [], externalMessages = [], progress = "default", extra = 'default', promptwindow, setPromptWindow}) => {
  const navigate = useNavigate();
  const [windowOpen, setWindowOpen] = useState(false);
  const [gtmWindowOpen, setGtmWindowOpen] = useState(false);
  const [gptWindowOpen, setGptWindowOpen] = useState(false);
  const [insightWindowOpen, setInsightWindowOpen] = useState(false);
  const [openAIMenuOpen, setOpenAIMenuOpen] = useState(false);
  const [isExecuted, setIsExecuted] = useState(false);
  useEffect(() => {
    if (!isExecuted && extra === 'tamsam') {
      console.log("open tamsam window");
      setWindowOpen(true); // 상태 업데이트
      const newWindow = window.open(
        'https://chatgpt.com/g/g-FGRnvdJaD-fermi-estimator',
        '_blank',
        'noopener,noreferrer,width=800,height=600'
      );
      if (newWindow) {
        setIsExecuted(true); // 실행 완료로 설정
      }
    }
  }, [extra, isExecuted]);

  useEffect(() => {
      if (!isExecuted && extra === 'tamsaminsight') {
        console.log("open tamsaminsight window");
        setInsightWindowOpen(true); // 상태 업데이트
      window.open(
        'https://chatgpt.com/g/g-FGRnvdJaD-fermi-estimator',
        '_blank',
        'noopener,noreferrer,width=800,height=600'
      );
      setIsExecuted(true); // 실행 완료로 설정
    }
  }, [extra, isExecuted]);

  useEffect(() => {
    console.log("externalMessages from Navbar: ", externalMessages);
  }, [externalMessages]);

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleMenuItemClick = (item) => {
    if (item === 'manual') {
      if(extra === 'tamsam'){
        setWindowOpen(!windowOpen);
      }
    }
    if (item === 'gpt') {
      setGptWindowOpen(!gptWindowOpen);
      setPromptWindow(!promptwindow);
    }
    if (item === 'insight') {
      setInsightWindowOpen(!insightWindowOpen);
    }
    if (item === 'gtm') {
      setGtmWindowOpen(!gtmWindowOpen);
    }
  };

  const handleOpenAIClick = () => {
    setOpenAIMenuOpen(!openAIMenuOpen);
  };

  return (
    <>
      <Drawer variant="permanent" open={open}>
        <Toolbar sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', px: [1] }}>
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          <ListItemButton onClick={() => handleNavigation('/ConsultantMypage')}>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="내 정보" />
          </ListItemButton>
          <ListItemButton onClick={() => handleNavigation('/ConsultantUsers')}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="고객 정보" />
          </ListItemButton>
          <ListItemButton onClick={() => handleNavigation('/ConsultantConsulting')}>
            <ListItemIcon>
              <SummarizeIcon />
            </ListItemIcon>
            <ListItemText primary="컨설팅 목록" />
          </ListItemButton>
          {/* <ListItemButton onClick={() => handleNavigation('/ConsultantQuestions')}>
            <ListItemIcon>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText primary="질문지" />
          </ListItemButton> */}
        </List>
        <Divider />
        {progress === "default" ? <></> :
        <List component="nav">
          <ListSubheader component="div" inset>
            추가 기능
          </ListSubheader>
          
          {progress === 'strategy' ? 
          <ListItemButton onClick={() => {handleMenuItemClick('gtm'); }}>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText>GTM 전략 캔버스<br></br>Workshop 가이드</ListItemText>
        </ListItemButton>
         :
          <ListItemButton onClick={() => { handleMenuItemClick('manual'); window.open('https://chatgpt.com/g/g-FGRnvdJaD-fermi-estimator', '_blank', 'noopener,noreferrer,width=800,height=600'); }}>
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            {progress === "fact" ? <ListItemText primary="TAM/SAM 추론" /> : <ListItemText primary="SOM 특성" />}
          </ListItemButton>
          }
          
          {progress === "insight" ?
          <ListItemButton onClick={() => { window.open('https://chatgpt.com/g/g-e7GpdobKd-persona-creator', '_blank', 'noopener,noreferrer,width=800,height=600'); }}>
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Persona 개발하기" />
          </ListItemButton>
          : <></>
          }
          <ListItemButton onClick={handleOpenAIClick}>
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Open AI" />
            {openAIMenuOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openAIMenuOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }} onClick={() => handleMenuItemClick('gpt')}>
                <ListItemIcon><img src='/chatgpt.ico' alt='Open AI Icon' style={{ width: 24, height: 24 }} />
                </ListItemIcon >
                <ListItemText primary="ChatGPT" />
              </ListItemButton>
              <ListItemButton sx={{ pl: 4 }} onClick={() => window.open('https://chatgpt.com/g/g-FGRnvdJaD-fermi-estimator', '_blank', 'noopener,noreferrer,width=800,height=600')}>
                <ListItemIcon><img src='/fermi_estimator.ico' alt='Open AI Icon' style={{ width: 24, height: 24 }} />
                </ListItemIcon>
                <ListItemText primary="Fermi Estimator" />
              </ListItemButton>
              <ListItemButton sx={{ pl: 4 }} onClick={() => window.open('https://chatgpt.com/g/g-e7GpdobKd-persona-creator', '_blank', 'noopener,noreferrer,width=800,height=600')}>
                <ListItemIcon><img src='/persona_creator.ico' alt='Open AI Icon' style={{ width: 24, height: 24 }} />
                </ListItemIcon>
                <ListItemText primary="Persona Creator" />
              </ListItemButton>
            </List>
          </Collapse>
          <ListItemButton onClick={() => window.open("https://www.perplexity.ai", '_blank', 'noopener,noreferrer,width=800,height=600')}>
            <ListItemIcon><img src='/perplexity.ico' alt='Open AI Icon' style={{ width: 24, height: 24 }} />
            </ListItemIcon>

              <ListItemText primary="Perplexity AI" />
          </ListItemButton>

          <ListItemButton onClick={() => window.open("https://www.claude.ai", '_blank', 'noopener,noreferrer,width=800,height=600')}>
            <ListItemIcon><img src='/claude.ico' alt='Open AI Icon' style={{ width: 24, height: 24 }} />
            </ListItemIcon>
            <ListItemText primary="Claude AI" />
          </ListItemButton>
        </List>
    }
      </Drawer>
      {windowOpen && (
        <Window
          id="react-window2"
          height={window.innerHeight / 2}
          width={window.innerWidth / 2}
          left={window.innerWidth / 3}
          top={window.innerHeight / 3}
          resizable={true}
          titleBar={{
            title: "TAM/SAM 추론",
            buttons: { minimize: true, maximize: true, close: () => setWindowOpen(!windowOpen) }
          }}
          style={{ backgroundColor: 'white' }}
        >
          <TamSamManual />
        </Window>
      )}
      {gptWindowOpen && (
        <Window
          id="react-window2"
          height={window.innerHeight / 2}
          width={window.innerWidth / 2}
          left={window.innerWidth / 3}
          top={window.innerHeight / 3}
          resizable={true}
          titleBar={{
            title: "Chat Gpt",
            buttons: { minimize: true, maximize: true, close: () => setGptWindowOpen(!gptWindowOpen) }
          }}
          style={{ backgroundColor: 'white' }}
        >
          <ChatwithGpt 
          initialMessages={initialMessages}
          externalMessages={externalMessages}/>
        </Window>
      )}
      {insightWindowOpen && (
        <Window
          id="react-window2"
          height={window.innerHeight / 2}
          width={window.innerWidth / 2}
          left={window.innerWidth / 3}
          top={window.innerHeight / 3}
          resizable={true}
          titleBar={{
            title: "SOM 도출 프롬프트 메뉴얼",
            buttons: { minimize: true, maximize: true, close: () => setInsightWindowOpen(!insightWindowOpen) }
          }}
          style={{ backgroundColor: 'white' }}
        >
          <SomManual />
        </Window>
      )}
      {gtmWindowOpen && (
        <Window
          id="react-window2"
          height={window.innerHeight / 2}
          width={window.innerWidth / 2}
          left={window.innerWidth / 3}
          top={window.innerHeight / 3}
          resizable={true}
          titleBar={{
            title: "GTM 전략 캔버스 Workshop 가이드",
            buttons: { minimize: true, maximize: true, close: () => setGtmWindowOpen(!gtmWindowOpen) }
          }}
          style={{ backgroundColor: 'white' }}
        >
          <GtmManual />
        </Window>
      )}
    </>
  );
};


export default DrawerComponent;
