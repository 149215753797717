import React, { useState, useEffect } from 'react';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Navbar from './Navbar/Navbar';
import LeftNavbar from './Navbar/LeftNavbar';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Fab from '@mui/material/Fab';
import RightArrow from '@mui/icons-material/KeyboardArrowRight';
import LeftArrow from '@mui/icons-material/KeyboardArrowLeft'

const API_URL = process.env.REACT_APP_API_ORIGIN;

const ConsultantFact1 = () => {
    const clientId = localStorage.getItem('clientId');
    const [open, setOpen] = useState(true);
    const [uid, setUid] = useState('');
    const [data, setData] = useState([]);
    const [btype, setBtype] = useState('null');
    const navigate = useNavigate();

    const toggleDrawer = () => {
        setOpen(!open);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const uidResponse = await Axios.get(`${API_URL}/auth/getUidByUserId/userId=${clientId}`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                setUid(uidResponse.data);

                const btyperesponse = await Axios.get(`${API_URL}/auth/getBusinessType`, {
                    params: {
                        uid: uidResponse.data,
                    },
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const btypeValue = btyperesponse.data.split('_')[0];
                setBtype(btypeValue);

                const [response, answerResponse] = await Promise.all([
                    fetch(`${API_URL}/api/questions`),
                    fetch(`${API_URL}/api/answers/user/uid=${uidResponse.data}`),
                ]);

                let question = await response.json();
                let savedAnswer = await answerResponse.json();

                question = question.slice(0, 10).map(({ questionId, question, questionClass }) => ({
                    questionId,
                    question,
                    questionClass,
                }));

                savedAnswer = savedAnswer.slice(0, 10).map(({ questionId, answerText }) => ({
                    questionId,
                    answerText,
                }));

                const tempdata = question.map(q => {
                    const answer = savedAnswer.find(a => a.questionId === q.questionId);
                    return {
                        ...q,
                        answerText: answer ? answer.answerText : null,
                    };
                });

                const groupedData = [];
                for (let i = 0; i < tempdata.length; i += 2) {
                    groupedData.push(tempdata.slice(i, i + 2));
                }

                setData(groupedData);

            } catch (error) {
                console.error('Error submitting form:', error);
            }
        };

        fetchData();
    }, [clientId]);


    return (
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Navbar open={open} toggleDrawer={toggleDrawer} />
                <LeftNavbar open={open} toggleDrawer={toggleDrawer} progress={'fact'}/>
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <h2>고객의 사업유형은 {btype}형 입니다.</h2>
                        <hr style={{ width: '100%', marginBottom: '20px' }} /> {/* 가로줄 추가 */}

                        <div className="table-container">
                            <table style={{ marginBottom: "20px" }}>
                                <thead>
                                    <tr>
                                        <th colSpan="5" style={{ backgroundColor: '#BDD7EE' }}>사업모델 유형</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((row, rowIndex) => (
                                        <tr key={rowIndex}>
                                            <td className="cell">{row[0]?.questionClass}</td>
                                            <td className="cell checkbox-cell">
                                                <input
                                                    type="checkbox"
                                                    checked={row[0]?.answerText === "예"}
                                                    readOnly
                                                />
                                            </td>
                                            <td className="cell">{row[0]?.question}</td>
                                            {row[1] && (
                                                <>
                                                    <td className="cell checkbox-cell">
                                                        <input
                                                            type="checkbox"
                                                            checked={row[1]?.answerText === "예"}
                                                            readOnly
                                                        />
                                                    </td>
                                                    <td className="cell">{row[1]?.question}</td>
                                                </>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </Container>
                </Box>
                <Fab color="secondary"
                    varient="extended"
                    onClick={() => navigate('/consultantfact2')}
                    sx={{
                        position: 'fixed',
                        width: 100,
                        bottom: 30,
                        right: 30,
                        borderRadius: 5, // 직사각형으로 만들기 위해 borderRadius 값을 조절합니다.
                        boxShadow: '0, 0, 0, 0.5',
                    }} >
                    <span>다음</span>
                    <RightArrow />
                </Fab>
                <Fab color="secondary"
                    varient="extended"
                    onClick={() => navigate('/consultantfile')}
                    sx={{
                        position: 'fixed',
                        width: 100,
                        bottom: 30,
                        right: 150,
                        borderRadius: 5, // 직사각형으로 만들기 위해 borderRadius 값을 조절합니다.
                        boxShadow: '0, 0, 0, 0.5',
                    }} >
                    <span>이전</span>
                    <LeftArrow />
                </Fab>
            </Box>
    );
};

export default ConsultantFact1;
