// Dashboard.js
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {Button, Box, CssBaseline, ThemeProvider,Grid } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Navbar from './Navbar/Navbar';
import LeftNavbar from './Navbar/LeftNavbar';
import Chat from './Chat_fact3';
import Chat1 from './Chat_fact1';
import Chat2 from './Chat_fact2';
import ChatFact from './ChatFact';
import Documents from './Document';
import Document1 from './Document_1';
import Document2 from './Document_2';
import Toolbar from '@mui/material/Toolbar';
import Window from './Window/Window';
import styles from '../../styles/factTable.module.css';
import '../../styles/ProgressBar.css'
import {Typography, Divider } from '@mui/material';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import qs from 'qs';
import { gridColumnGroupsLookupSelector } from '@mui/x-data-grid';
import {updatestatus} from '../Utils'

const API_URL = process.env.REACT_APP_API_ORIGIN;

const Dashboard = () => {
  const uid = localStorage.getItem('uid');
  const [open, setOpen] = useState(true);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [isQASessionOver, setIsQASessionOver] = React.useState(false);
  const [questions, setQuestions] = React.useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [businessType,setBusinessType] = useState();
  const [category1,setCategory1] = useState(null);
  const [category2,setCategory2] = useState(null);
  const [categoryAlert,setCategoryAlert] = useState(false);
  const [userStatus,setUserStatus] = React.useState(parseInt(localStorage.getItem('userStatus')));
  const navigate = useNavigate();
  const [questionList1,setQuestionList1] = useState([]);
  const [questionList2,setQuestionList2] = useState([]);
  const [questionList3,setQuestionList3] = useState([]);
  const [question1Index,setQuestion1Index] = useState(0);
  const [question2Index,setQuestion2Index] = useState(0);
  const [question3Index,setQuestion3Index] = useState(0);
  const [buttonType,setButtonType] = useState(false);
  const [data,setData] = useState();
  const [chatbotStep,setChatbotStep] = useState([0,0,0]);
  const [userData, setUserData] = useState(null);
  const [offeringValues, setOfferingValues] = useState(null);

  const documentRef = useRef(null);
  
  const moveToNextStep = async() => {
    if(currentStep==2)//사업아이디어진단
    {
      setCurrentQuestionIndex(question2Index);
      if(userStatus<4){//첫 제출일때만 호출
        await updatestatus(uid,'fact',4);
        await updatestatus(uid, 'insight', 1);
        sendEmails('고객의 사업아이디어 진단의 답변이 모두 완료되었습니다. 확인해 주십시오. <br><br> 이 이메일은 발신 전용 이메일 입니다. 회신하시더라도 답변되지 않습니다.');
        fillInsightKeywordWithGpt();
      }
      
      navigate("/FactReport");
      return;
    }
    if(currentStep == 0)//사업유형진단
    {
      // const type1 = classifyCategory1();

      // console.log(type1)
      // if(type1 == '보류'){
      //   setCategoryAlert(true); // 보류되었음을 알려줌
      //   return;
      // }
      // setCategory1(type1);
      if(userStatus < 2) //최초 접근 -> status업데이트
      {
        await updatestatus(uid, 'fact', 2);
        setUserStatus(2);
      }
      setCurrentStep(currentStep+1);
      setFilteredQuestions(questionList2)
      setCurrentQuestionIndex(question2Index);
      setIsQASessionOver(false)
    }
    else if(currentStep == 1)//시장유형진단
    {
        setCategoryAlert(true);
    }
  }

  const fillInsightKeywordWithGpt = async () =>{
    // console.log("최종 제출",filteredQuestions);
    const postData = filteredQuestions.map(question => ({
      uid: uid,  
      question_id: question.questionId,
      question: question.question,
      answer: question.answerText
    }));
  
    axios.post(`${API_URL}/api/fact/keyword/as-is`, postData)
    .then(response => {
        console.log("keyword asis 생성 성공");
    })
    .catch(error => {
        console.error('Error:', error.response);
    });
  
  
  }

//email
  const sendEmails = async (body) => {
    console.log(userData);
    if (userData) {
      try {
        for (const user of userData) {
          const emailData = new URLSearchParams();
          emailData.append('from', 'FISA@stepstonekr.com');
          emailData.append('to', user.email);
          emailData.append('subject', '알림');
          emailData.append('body', body);

          await axios.post(`${API_URL}/api/email/send`, emailData, { 
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          });
        }
        console.log('Emails sent successfully');
      } catch (error) {
        console.error('Error sending emails:', error);
      }
    }
  };

  // const classifyCategory1 = () => {

  //   let type1
  //   const category1_valuechain = filteredQuestions.filter(question => [1, 3, 5, 8, 9].includes(question.questionId) && question.answerText === '예').length;
  //   const category1_platform = filteredQuestions.filter(question => [2, 4, 6, 7, 10].includes(question.questionId) && question.answerText === '예').length;
  //   console.log("사업유형",category1_valuechain,category1_platform)
  //   // 사업유형
  //   if (category1_valuechain > category1_platform)
  //     type1 = "밸류체인";
  //   else
  //     type1 = "플랫폼";
  //   // if (category1_valuechain >= 4 && category1_platform < 4) {
  //   //   type1=('밸류체인');
  //   // } else if (category1_platform >= 4 && category1_valuechain < 4) {
  //   //   type1=('플랫폼');
  //   // } else{
  //   //   type1 =('보류');
  //   // }
  //   return type1
  // }
  const classifyCategory2 = () => {
    // console.log("분류전 내용들",questions)
    let type2
    const category2_blueOcean = questions.filter(question => [11, 13].includes(question.questionId) && question.answerText === '예').length === 2;
    const category2_newProduct = questions.filter(question => [12, 13].includes(question.questionId) && question.answerText === '예').length === 2;
    const category2_newCustomer = questions.filter(question => [11, 14].includes(question.questionId) && question.answerText === '예').length === 2;
    const category2_existingMarket = questions.filter(question => [12, 14].includes(question.questionId) && question.answerText === '예').length === 2;
    
    // 시장유형
    if (category2_blueOcean && !category2_newProduct && !category2_newCustomer && !category2_existingMarket) {
      type2 = '블루오션';
    } else if (category2_newProduct && !category2_blueOcean && !category2_newCustomer && !category2_existingMarket) {
      type2 = '신제품';
    } else if (category2_newCustomer && !category2_newProduct && !category2_blueOcean && !category2_existingMarket) {
      type2 ='신규고객';
    } else if (category2_existingMarket && !category2_newProduct && !category2_newCustomer && !category2_blueOcean) {
      type2 = '기존시장';
    } else{
      type2 = '보류';
    }
    return type2
  };
  const handleStepClick = (step) => {
    let questionList, questionIndex;
  
    if (step === 0) {
      questionList = questionList1;
      questionIndex = question1Index;
    } else if (step === 1) {
      questionList = questionList2;
      questionIndex = question2Index;
    } else {
      questionList = questionList3;
      questionIndex = question3Index;
    }
  
    setFilteredQuestions(questionList);
    setCurrentQuestionIndex(questionIndex);
    setIsQASessionOver(questionIndex >= questionList.length - 1);
    setCurrentStep(step);
  };
  

  const updateUserStatus = async (updateValue) => {
    try {
      const uid = localStorage.getItem('uid');
      const response = await axios.post(`${API_URL}/auth/setStatus`, {
        uid: uid,
        status: updateValue,
      });
      console.log("userStatus updated successfully");
      localStorage.setItem('userStatus',updateValue);
      return response.data;
    } catch (error) {
      // 요청이 실패하면 에러를 반환
      throw error;
    }
  };

  const updateBussinessType = async (data) => {
    try {
        const response = await axios.post(`${API_URL}/auth/setBusinessType`, {
            uid: uid,
            businessType: data,
        });
        setBusinessType(data);
        if (userStatus <= 1)
          setCategory1(data);
        console.log(response.data);
    } catch (error) {
        console.error('Error submitting category:', error);
    }
  };

  // const updateBussinessTypeAndOfferingType = async (data) => {
  //   try {
  //       const response = await axios.post(`${API_URL}/auth/setBusinessTypeAndOfferingType`, {
  //           uid: uid,
  //           businessType: data.businessType,
  //           offeringType: data.offeringType,
  //       });
  //       setBusinessType(data.businessType);
  //       setOfferingValues(data.offeringType);
  //       if (userStatus <= 1)
  //         setCategory1(data.businessType);
  //       console.log(response.data);
  //   } catch (error) {
  //       console.error('Error submitting category:', error);
  //   }
  // };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  // 처음에 질문과 답변을 불러옴
  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("question호출되었습니다")
        const uid = localStorage.getItem('uid');
        const [response, answerResponse] = await Promise.all([
          fetch(`${API_URL}/api/questions`),
          fetch(`${API_URL}/api/answers/user/uid=${uid}`),
        ]);
        const data = await response.json();
        const savedAnswer = await answerResponse.json();
        console.log("questions",data);
        console.log("savedAnswer",savedAnswer);

        const engageresponse = await axios.get(`${API_URL}/consultant/getConsultEngagement/clientUid=${uid}`);
        console.log(engageresponse);

        // Assuming response.data contains an array of consultants
        const consultantIds = new Set(engageresponse.data.map(item => item.consultantId));

        const userdata = await fetch(`${API_URL}/consultant/getUserList`);
        const engagedata = await userdata.json();
        console.log(engagedata);

        // Filter to keep only users with ids in the consultantIds set
        const filteredUsers = engagedata.filter(user => consultantIds.has(user.id));
        setUserData(filteredUsers);

        const questionsWithSavedAnswer = data.map(question => {
          const matchingAnswer = savedAnswer.find(answer => answer.questionId === question.questionId);
        
          if (matchingAnswer) {
            if (matchingAnswer.modifiedQuestion && matchingAnswer.modifiedQuestion.trim() !== "") 
              
              question.question = matchingAnswer.modifiedQuestion
            
            return {
              ...question,
              answerText: matchingAnswer.answerText,
            };
          }  
          return question;
        });
        let questionData1=[];
        let questionData2=[];
        let questionData3=[];
        let businessCategory;
        let initialStep =0;

        const [statusData, businessTypeData, offeringTypeData] = await Promise.all([
          getUserStatus(),
          getBusinessType(),
          getOfferingType()
        ]);
        setOfferingValues(offeringTypeData);

        if (statusData < 3)
          {
            const parts = businessTypeData.split("_");

            if (parts.length > 1) {
              setCategory1(parts[0]);
              setCategory2(parts[1]); 
            } else if (parts.length == 1){
              setCategory1(parts[0]);
              setCategory2("");  // category2를 초기화하거나 적절한 기본값으로 설정
            }
            console.log("category",businessTypeData)
            if(statusData == 2){
              setCurrentStep(1);
              initialStep = 1;
            }
            else
              initialStep = 0;
          }
        else if (statusData>=3)
          {
            const parts = businessTypeData.split("_");
            console.log("here parts",parts);
            if (parts.length > 1) {
              setCategory1(parts[0]);
              setCategory2(parts[1]); 
              console.log("parts",parts[0],parts[1]);
            } else if (parts.length == 1){
              setCategory1(parts[0]);
            }
            console.log("category",businessTypeData)
          businessCategory = "사업아이디어진단(" + businessTypeData + ")";
          console.log("category",businessCategory)
          setBusinessType(businessCategory);
          setCurrentStep(2);
          initialStep = 2;
        }


        questionsWithSavedAnswer.forEach(question => {
          if (question.questionType === "사업유형진단") {
            questionData1.push(question);
          } else if (question.questionType === "시장유형진단") {
            questionData2.push(question);
          } else if (statusData>=3 && question.questionType === businessCategory && question.question.trim() !== "내용 없음") {
            questionData3.push(question);
          }
        });
        console.log("list1",questionData1);
        
        console.log("hi",questionsWithSavedAnswer);
        console.log("initialStep",initialStep);
        
        setQuestionList1(questionData1);
        setQuestionList2(questionData2);
        setQuestionList3(questionData3);
        if(initialStep === 0){
          setFilteredQuestions(questionData1);
        }
        else if(initialStep === 1){
          setFilteredQuestions(questionData2);
        }
        else if (initialStep === 2){// 사업아이디어단계
          setFilteredQuestions(questionData3);
          console.log("QaSession",isQASessionOver);
          console.log("FillterQUestion출력",currentQuestionIndex,questionData3);
          const QuestionWithAnswer = questionData3.filter(q => q.answerText != null && q.answerText != "");
          if (QuestionWithAnswer.length == questionData3.length) {
            console.log(isQASessionOver);
            setIsQASessionOver(true);
            console.log(isQASessionOver);
          }
        }
        const rows = [];
        for (let i = 0; i < questionData1.length; i += 2) {
          rows.push([questionData1[i], questionData1[i + 1]]);
        }
        setData(rows);
      

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); 
  }, []); // useEffect가 마운트 될 때 한 번만 호출


  const getBusinessType = async () => {
    try {
      const response = await axios.get(`${API_URL}/auth/getBusinessType`, {
        params: {
          uid: uid
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log(response.data);
      return(response.data);
    } catch (error) {
      // Handle errors
      console.error('Error getting businessType:', error);
    }
};

const getOfferingType = async () => {
  try {
    const response = await axios.get(`${API_URL}/auth/getOfferingType`, {
      params: {
        uid: uid
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
    // console.log(response.data);
    return(response.data);
  } catch (error) {
    // Handle errors
    console.error('Error getting offeringType:', error);
  }
};
  const getUserStatus = async () => {
    try {
      const response = await axios.get(`${API_URL}/auth/getStatus`, {
        params: {
          uid: uid
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log(response.data);
      setUserStatus(response.data);
      return (response.data);

    } catch (error) {
      // Handle errors
      console.error('Error getting UserStatus:', error);
    }
  };

  useEffect(() => {
    setCurrentQuestion(filteredQuestions[currentQuestionIndex])
    // setCategory1(classifyCategory1());

  }, [filteredQuestions,currentQuestionIndex]);
  
  useEffect(() => {
    if (currentStep < 2)
      setButtonType(true)
    else
      setButtonType(false)

  }, [currentStep]);
  

  //chat에서 답변send했을때
  const handleAnswerSubmit = (answeredQuestion) => {
    console.log(isQASessionOver)
    if (!isQASessionOver) {
    
      const updatedQuestions = filteredQuestions.map((q, question_index) => {
        if (q.questionId === answeredQuestion.questionId) {
          console.log(question_index);
          console.log(q);
          return { ...q, uid: uid, answerText: answeredQuestion.answer };
        }
        return q;
      });
      setFilteredQuestions(updatedQuestions)
      if(currentStep === 0){
        setQuestionList1(updatedQuestions);
      }
      else if(currentStep ===1){
        setQuestionList2(updatedQuestions);
      }
      else{
        setQuestionList3(updatedQuestions);
      }
    }
  };

  const setNextQuestion = () => {
    //질문끝났는지 확인
    if (currentQuestionIndex >= filteredQuestions.length - 1) {
      setIsQASessionOver(true);
      return;
    }
    
    const nextIndex = currentQuestionIndex + 1;
    
    if(currentStep === 0){
      setQuestion1Index(question1Index+1);
    }
    else if(currentStep ===1){
      setQuestion2Index(question2Index+1);
    }
    else{
      setQuestion3Index(question3Index+1);
    }
    //다음 질문
    setCurrentQuestion(filteredQuestions[nextIndex]);
    setCurrentQuestionIndex(nextIndex);
    console.log("nextIndex", nextIndex);
  };



  if (!currentQuestion) {
    console.log("No question")
    return null;
  }

  const CategoryAlertDialog = ({onClose}) => {
    let sentence1;
    let sentence2;
    const severity = (currentStep === 0) ? 'warning' : 'info';
    if(currentStep == 0){
      sentence1 = "창업자가 하고자 하는 사업유형이 정확하게 판단되지 않습니다.";
      sentence2 = "질문을 다시 확인하여 예/아니오를 적어주세요. 문제가 지속될 경우 컨설턴트에게 문의 부탁드립니다.";
    }
    else{
      sentence1 = "저장된 답변을 토대로 창업자의 시장유형을 판단합니다.";
      sentence2 = "컨설턴트의 확인 후 다음 단계로 진행되며, 시간이 소요될 수 있습니다.";
    }
    const handleClick = () => {
      if (currentStep === 0) {
        onClose(); // currentStep이 0이면 onClose 함수 호출
      } else{
      setTimeout(() => {
        sendEmails('고객의 유형진단 답변이 완료되었습니다. 사업아이디어 진단을 위한 단계를 완료해주십시오. <br><br> 이 이메일은 발신 전용 이메일 입니다. 회신하시더라도 답변되지 않습니다.');
        navigate("/home"); // currentStep이 1이면 navigate 함수를 사용하여 페이지 이동
        }, 1000); // 1초 후에 페이지 이동
      }
    }
    return (
      <Dialog open={categoryAlert} onClose={onClose}>
  
        <DialogTitle>{sentence1}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Alert severity={severity} >
                {sentence2}
            </Alert>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClick} color="primary">
            확인
          </Button>
        </DialogActions>
      </Dialog>
    );
  };


  return (
      <Box  className={styles.factTable} sx={{ display: 'flex' }}>
        <CssBaseline />
        <Navbar open={open} toggleDrawer={toggleDrawer}/>
        <LeftNavbar open={open} toggleDrawer={toggleDrawer}/>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container sx={{ mt: 4, mb: 4 }}>
            <Grid item xs={12}>
                <Typography variant="h4" gutterBottom align="left" marginBottom={2}>
                    Fact단계
                <Divider />
                </Typography>
            </Grid>
        <div className="progress-bar" style={{ display: 'flex' }}>
          <div
            className={`step ${currentStep === 0 ? 'active' : userStatus >= 1 ? 'completed' : ''}`}
            style={{ pointerEvents: currentStep === 0 || userStatus >= 1 ? 'auto' : 'none' }}
            onClick={() => {
              if (currentStep == 0 || userStatus >= 1) {
                handleStepClick(0);
              }
            }}
          >
            1. 사업유형진단
          </div>
          <div
            className={`step ${currentStep === 1 ? 'active' : userStatus >= 2 ? 'completed' : ''}`}
            style={{ pointerEvents: currentStep === 1 || userStatus >= 2 ? 'auto' : 'none' }}
            onClick={() => {
              if (currentStep == 1 || userStatus >= 2) {
                handleStepClick(1);
              }
            }}
          >
            2. 시장유형진단
          </div>
          <div
            className={`step ${currentStep === 2 ? 'active' : userStatus >= 3 ? 'completed' : ''}`}
            style={{ pointerEvents: currentStep === 2 || userStatus >= 3 ? 'auto' : 'none' }}
            onClick={() => {
              if (currentStep === 2 || userStatus >= 3) {
                handleStepClick(2);
              }
            }}
          >

            3. 사업아이디어진단
          </div>
        </div>
        {/* {(currentStep !== 2 || isQASessionOver) && ( */}
            <Window
              id="react-window2"
              // effectiveHeight.current = window.innerHeight / 1.2;
              // effectiveWidth.current = window.innerWidth / 1.5;
              // height={window.innerHeight/2}
              // width={window.innerWidth/2}
              width={750}
              height={400}
              left={window.innerWidth/2.1}
              top={window.innerHeight/3.2}
              resizable={true}
              titleBar={{
                // icon: "R",
                title: "대답 목록",
                buttons: { minimize: true, maximize: true, close: false }
              }}
              style={{ backgroundColor: 'white', paddingLeft:10, zIndex: 1200 }}
            >
             {currentStep == 0 &&<Document1 dataform={data} questions={filteredQuestions} moveToNextStep={moveToNextStep} setIsQASessionOver={setIsQASessionOver} updateBusinessType={updateBussinessType} userStatus={userStatus} ref={documentRef}/>}
             {currentStep == 1 &&<Document2 setChatbotStep={setChatbotStep} category1={category1} category2={category2} setCategory2={setCategory2} moveToNextStep={moveToNextStep} offeringValues={offeringValues}/>}
             {currentStep == 2 && <Documents questions={filteredQuestions} moveToNextStep={moveToNextStep} isQASessionOver={isQASessionOver} ref={documentRef}/>}
             {/* {currentStep == 2 && isQASessionOver && <Documents questions={filteredQuestions} moveToNextStep={moveToNextStep} ref={documentRef}/>} */}
       </Window>
       {/* </Window>)} */}
              
          </Container>
        </Box>
        <Window
          id="react-window3"
          height={470}
          width={630}
          left={window.innerWidth / 5.5}
          top={window.innerHeight / 3.2}
          resizable={true}
          titleBar={{
            title: "Chat",
            buttons: { minimize: true, maximize: true, close: false }
          }}
        >
      {currentStep==0 && <Chat1
        currentQuestion={currentQuestion}
        onAnswerSubmit={handleAnswerSubmit}
        setNextQuestion={setNextQuestion}
        isQASessionOver={isQASessionOver}
        step={currentStep}
        category1={category1}
        buttonType={buttonType}
      />}
      {currentStep==1 && <Chat2
        currentQuestion={currentQuestion} 
        onAnswerSubmit={handleAnswerSubmit} 
        setNextQuestion={setNextQuestion}
        chatbotStep={chatbotStep}
        setChatbotStep={setChatbotStep}
        step={currentStep}
        buttonType={buttonType}
      />}
      {currentStep==2 && <Chat
        currentQuestion={currentQuestion}
        onAnswerSubmit={handleAnswerSubmit} 
        setNextQuestion={setNextQuestion}
        isQASessionOver={isQASessionOver}
        step={currentStep} 
        buttonType={buttonType}
      />}
  
  </Window>
      <CategoryAlertDialog onClose={() => setCategoryAlert(false)}/>
      </Box>
    );
};

export default Dashboard;