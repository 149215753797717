import * as React from 'react';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Navbar from './Navbar/Navbar';
import LeftNavbar from './Navbar/LeftNavbar';
import 'react-data-grid/lib/styles.css';
import { useEffect, useState, useRef } from 'react';
import Table2 from './CTable2';
import '../../styles/TamSamInsight.css';
import Textarea from '@mui/joy/Textarea';
import Fab from '@mui/material/Fab';
import RightArrow from '@mui/icons-material/KeyboardArrowRight';
import { green } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import { Button } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Window from '../Customer/Window/Window';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ChatwithGpt from './ChatwithGpt';
import renderTableFromJson from './ChatwithGpt'
import CreatePersona from './InsightCreatePersona';
import StrategyEvaluatePersona from '../Customer/StrategyEvaluatePersona';
const API_URL = process.env.REACT_APP_API_ORIGIN;

export default function TamSamInsight() {
    const [rows, setRows] = useState([]);
    const [clientUid, setClientUid] = useState('');
    const [editIdx, setEditIdx] = useState(-1);
    const [open, setOpen] = useState(false);//제출확인창
    const [openSnackbar, setOpenSnackbar] = useState(false);//수정완료창
    const navigate = useNavigate();
    const [businessval, setBusinessVal] = useState("");
    const [navopen, setNavOpen] = useState(true);

    const [asIsText, setAsIs] = useState("");
    const [insightText, setInsight] = useState("");

    const [keywords, setKeywords] = useState("null");
    const [initialMessages, setInitialMessages] = useState([]);
    const [externalMessages, setExternalMessages] = useState([]);
    const [promptFromDB, setPromptFromDB] = useState('');


    // useEffect(() => {
    //     const businessval2 = getBusinessType();
    //     setBusinessVal(businessval2);
    //     console.log("businessval2", businessval2)

    // }, []);

    function generatePrompt(prompt, role='', asIsText='', insightText='', goal='', keywords='') {
        prompt = prompt.replace(/\${role}/g, role);
        prompt = prompt.replace(/\${asIsText}/g, asIsText);
        prompt = prompt.replace(/\${insightText}/g, insightText);
        prompt = prompt.replace(/\${goal}/g, goal);
        prompt = prompt.replace(/\${keywords}/g, keywords);
        return prompt;
    }
    

    useEffect(() => {
        const fetchData = async () => {
            try {
                const clientId = localStorage.getItem('clientId');
                const clientUid = await getUidById(clientId);
                setClientUid(clientUid);
    
                const [response, answerResponse, promptResponse] = await Promise.all([
                    fetch(`${API_URL}/api/insight`),
                    fetch(`${API_URL}/api/answers/user/uid=${clientUid}`),
                    fetch(`${API_URL}/api/prompt/usageArea=f`)
                ]);
                const data = await response.json();
                const savedAnswer = await answerResponse.json();
                const promptFromDB = await promptResponse.json();
    
                const businessval2 = await getBusinessType();
                setBusinessVal(businessval2);
    
                const questionsWithSavedAnswer = data.map((question) => {
                    const matchingAnswer = savedAnswer.find(answer => answer.questionId === question.questionId);
                    if (matchingAnswer) {
                        if (matchingAnswer.modifiedQuestion && matchingAnswer.modifiedQuestion.trim() !== "") {
                            question.question = matchingAnswer.modifiedQuestion;
                            question.isModified = true;
                        }
                        return {
                            ...question,
                            id: question.questionId,
                            answerText: matchingAnswer.answerText,
                            asIs: matchingAnswer.asIs,
                            keyword: matchingAnswer.keyword,
                            insight: matchingAnswer.insight,
                        };
                    }
                    return {
                        ...question,
                        id: question.questionId,
                    };
                });
    
                setRows(questionsWithSavedAnswer);
                setPromptFromDB(promptFromDB);
                // console.log("questionsWithSavedAnswer", questionsWithSavedAnswer);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);
    
    useEffect(() => {
        if (businessval) {  // Only run this effect if businessval is set
            const filteredQuestionsWithSavedAnswer = rows.filter(question => question.questionClass !== "추가질문" || question.isModified);

            const asIsTextString = calculateAsIsTextString(filteredQuestionsWithSavedAnswer, businessval);
            const insightTextString = calculateInsightTextString(filteredQuestionsWithSavedAnswer, businessval);

            setAsIs(asIsTextString);
            setInsight(insightTextString);

            console.log("asIsTextString", asIsTextString);
            console.log("insightTextString", insightTextString);
            
        }
    }, [businessval]);  // Depend on businessval and rows

    useEffect(() => {
        if (asIsText !== "" && insightText !== "") {
            var generatedPrompt = generatePrompt(promptFromDB.find(prompt => prompt.promptId === 'insight_tamsam_keyword').text, '', asIsText, insightText, '', ''); 

            fetch(`${API_URL}/ask-chatgpt`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Connection': 'keep-alive',
                },
                // body: JSON.stringify({ prompt: `###질문:다음 내용을 핵심 내용 위주로 간략하게 요약하고 고객(시장), 제품(서비스), 유통(마케팅), 경쟁우위(차별화) 그리고 이익창출에 대해서 
                // 각각 1~2개의 key word을 추출하고, 이를 모두 , 로 합쳐서 반환하시오\n###내용:\nAS-IS:${asIsText}\nInsight:${insightText}` }),

                body: JSON.stringify({ prompt:  generatedPrompt}),

                credentials: 'include', // Ensure cookies are sent with the request
              })
              .then(response => response.json())
              .then(data => {
                console.log('Success:', data);
                // Display the response to the user
                setKeywords(data.choices[0].message.content);
              })
              .catch((error) => {
                console.error('Error sending prompt:', error);
              });
            }


    }, [asIsText, insightText]);
    

    // 각 카테고리의 키워드 문자열을 계산하는 함수 예시
    function calculateAsIsTextString(questions, businessval) {
        return questions
            .map(q => q.asIs)
            .join(", ");
    }

    function calculateInsightTextString(questions, businessval) {
        return questions
            .map(q => q.insight)
            .join(", ");
    }

    const getUidById = async (clientId) => {
        try {
            const clientUidResponse = await fetch(`${API_URL}/auth/getUidByUserId/userId=${clientId}`);
            const clientUid = await clientUidResponse.text();
            return clientUid;
        }
        catch (error) {
            console.error('Error getting clientId:', error);
        }
    }

    function renderContent(message) {
        // if message starts with [json]:, parse the JSON data and render a table
        if (message.includes("[json]:")) {
            const textResult = message.split('[json]:')[0];
            const tableResult = message.split('[json]:')[1];

            const jsonData = JSON.parse(tableResult);
            console.log('Rendering JSON data:', jsonData);
        
    
            // 'Columns'와 'Data' 배열이 존재하는지 확인
            if (!jsonData || !jsonData.Columns || !jsonData.Data) {
                console.log('Invalid JSON data structure.');
                return null;
            }

            const columns = jsonData.Columns;
            const dataRows = jsonData.Data;
            
            console.log('Columns:', columns);
            console.log('Data rows:', dataRows);
        
            return (
                <div>
                <p>{textResult}</p>
                <table border="1" style={{ width: '100%', marginTop: '10px' }}>
                    <thead>
                        <tr>
                            {columns.map((col, index) => (
                                <th key={index} style={{ padding: '8px', border: '1px solid black' }}>{col}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {dataRows.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {row.map((cell, cellIndex) => (
                                    <td key={cellIndex} style={{ padding: '8px', border: '1px solid black' }}>{cell}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
                </div>
            );
            }

        if (message.startsWith("[image]:")) {
            const result4 = message.split('[image]:')[1];
            
            // imageUrls 배열에 여러 이미지 URL을 저장
            const imageUrls = result4.split('\n');

            // 이미지 URL 갯수만큼 img 태그 생성
            const images = imageUrls.map((url, index) => (
                <img key={index} src={url} alt={`Generated Image ${index}`} />
            ));
            return images;

        }

        if (message.includes("image")) {
            const result4 = message.split('image')[1];

            // imageUrls 배열에 여러 이미지 URL을 저장
            const imageUrls = result4.split('\n');

            // 이미지 URL 갯수만큼 img 태그 생성
            const images = imageUrls.map((url, index) => (
                <img key={index} src={url} alt={`Generated Image ${index}`} />
            ));
            return images;
        }

        if (message.includes("[이미지]")) {
            const result4 = message.split('[이미지]')[1];
            
            // imageUrls 배열에 여러 이미지 URL을 저장
            const imageUrls = result4.split('\n');

            // 이미지 URL 갯수만큼 img 태그 생성
            const images = imageUrls.map((url, index) => (
                <img key={index} src={url} alt={`Generated Image ${index}`} />
            ));
            return images;
        }

        else {
            return <p>{message}</p>;
        }
    }

    const sendUpdateToServer = async (updatedRow) => {

        try {
            if (updatedRow.isModified)
                updatedRow = { ...updatedRow, modifiedQuestion: updatedRow.question }

            const updateData = { ...updatedRow, uid: clientUid };

            // uid 속성 추가
            console.log("받은인자", updatedRow);
            console.log("updateData", updateData);

            await fetch(`${API_URL}/api/answers/update`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updateData),
            });
            console.log('updated successfully')
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };

    // const handleEdit = (idx) => {
    //     setEditIdx(idx); // 편집할 행의 인덱스를 설정합니다.
    // };

    // const handleSave = async (idx) => {
    //     setEditIdx(-1); // 편집 모드를 종료합니다.
    //     await sendUpdateToServer(rows[idx]); // 변경된 행을 서버로 전송합니다.
    //     setOpenSnackbar(true);
    // };

    // const handleChange = (e, idx, column) => {
    //     const updatedData = rows.map((row, index) =>
    //         index === idx ? { ...row, [column]: e.target.value } : row
    //     );
    //     setRows(updatedData); // 키워드 변경사항을 상태에 반영합니다.
    // };

    const getBusinessType = async () => {

        const clientId = localStorage.getItem('clientId');//현재 보고자하는 uid로 변경해줄것!!!
        const clientUid = await getUidById(clientId);
        try {
            const response = await axios.get(`${API_URL}/auth/getBusinessType`, {
                params: {
                    uid: clientUid,
                },
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log(response.data);
            return (response.data);
        } catch (error) {
            // Handle errors
            console.error('Error getting businessType:', error);
        }
    };

    const [anchorEl, setAnchorEl] = useState(null);
    // const [windowOpen, setWindowOpen] = useState(true);
    const [insightPromptWindowOpen, setInsightPromptWindowOpen] = useState(false);
    const [gptWindowOpen, setGptWindowOpen] = useState(false);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const toggleDrawer = () => {
        setNavOpen(!navopen);
    };

    const containerStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "20px",
        marginLeft: "10px",
        marginRight: "10px",
    };

    const tableStyle = {
        marginBottom: "20px",
    };

    const [selectedGoal, setSelectedGoal] = useState('TAM');

    const [prompt, setPrompt] = useState('프롬프트 생성 버튼을 눌러 생성해주세요');
    const [prompt2, setPrompt2] = useState('프롬프트 생성 버튼을 눌러 생성해주세요');
    const [prompt3, setPrompt3] = useState('프롬프트 생성 버튼을 눌러 생성해주세요');
    const [prompt4, setPrompt4] = useState('프롬프트 생성 버튼을 눌러 생성해주세요');
    const [newPrompt, setNewPrompt] = useState('');
    const [newPrompt2, setNewPrompt2] = useState('');
    const [newPrompt3, setNewPrompt3] = useState('');
    const [newPrompt4, setNewPrompt4] = useState('');
    const goalRef = useRef(null);
    const roleRef = useRef(null);
    const [promptResult, setPromptResult] = useState('프롬프트 생성기의 Send 버튼을 눌러 생성해주세요.');
    const [promptResult2, setPromptResult2] = useState('프롬프트 생성기의 Send 버튼을 눌러 생성해주세요.');
    const [promptResult3, setPromptResult3] = useState('프롬프트 생성기의 Send 버튼을 눌러 생성해주세요.');
    const [promptResult4, setPromptResult4] = useState('프롬프트 생성기의 Send 버튼을 눌러 생성해주세요.');

    const setRole = (role) => {
        roleRef.current.value = role;
    };

    const handleButtonClick = () => {
        if(keywords === "null"){
            return;
        }
        const role = roleRef.current.value;
        const goal = goalRef.current.value;

        if (goal === 'SOM 특성 추출') {
            var generatedPrompt = generatePrompt(promptFromDB.find(prompt => prompt.promptId === 'insight_tamsam_som1').text, role, asIsText, insightText, goal, keywords);
            setPrompt(generatedPrompt);

            generatedPrompt = generatePrompt(promptFromDB.find(prompt => prompt.promptId === 'insight_tamsam_som2').text, role, asIsText, insightText, goal, keywords);
            setPrompt2(generatedPrompt);

            generatedPrompt = generatePrompt(promptFromDB.find(prompt => prompt.promptId === 'insight_tamsam_som3').text, role, asIsText, insightText, goal, keywords);
            setPrompt3(generatedPrompt);

            generatedPrompt = generatePrompt(promptFromDB.find(prompt => prompt.promptId === 'insight_tamsam_som4').text, role, asIsText, insightText, goal, keywords);
            setPrompt4(generatedPrompt);

            // const generatedPrompt = `너는 ${role}. 아래의 핵심내용으로 ${goal}을 해줘. \n###핵심내용\n${keywords}`;
            // setPrompt(generatedPrompt);
            // const generatedPrompt2 = `위의 추출된 특성에 적합한 타켓 고객군에 대한 페르소나 3개를 만들어 주고 스토리텔링 형식으로 자세히 기술해줘. 그리고 
            // democraphic, psychographic,  shopping behavior 특성으로 구분해서 기술하고 표로 정리해줘. (반드시 Column이 각 페르소나의 이름, Data가 각각 democraphic, psychographic, shopping behavior가 되어야함)`;
            // setPrompt2(generatedPrompt2);
            // const generatedPrompt3 = `3개의 페르소나를 다음의 특징으로 구분해서 비교해주고 비교 내용은 상세하게 기술해서 엑셀표형식으로 정리해줘. 표의 행에는 페르소나의 이름, 열에는 특징으로 정리해줘. 특징(rows) : 최종 사용자, 용도, 혜택, 선도고객, 시장특성, 협력업체, 시장규모, 경쟁상황, 필수보완재.`;
            // setPrompt3(generatedPrompt3);
            // const generatedPrompt4 = `위의 페르소나에 대한 각각의 사진을 만들어줘. 그리고  고객에 대해서 우선 순위를 정한다면 어떻게 될 지 의견을 주고 근거를 설명해줘`
            // setPrompt4(generatedPrompt4);
        }

        else {
            setPrompt('추천 프롬프트는 현재 목표:SOM 특성 추출 대해서만 지원합니다.');
            setPrompt2('추천 프롬프트는 현재 목표:SOM 특성 추출 대해서만 지원합니다.');
            setPrompt3('추천 프롬프트는 현재 목표:SOM 특성 추출  대해서만 지원합니다.');
            setPrompt4('추천 프롬프트는 현재 목표:SOM 특성 추출  대해서만 지원합니다.');
        }
    };

    useEffect(() => {
        if (
            promptResult2 !== '생성을 시작합니다...' &&
            promptResult2 !== '프롬프트 생성기의 Send 버튼을 눌러 생성해주세요.' &&
            promptResult2 !== '생성 중...'
        ) {
            const botMessage = {
                text: promptResult2,
                sender: 'bot',
            };
    
            // 상태 업데이트 전과 후를 로그로 확인
            console.log("Previous externalMessages: ", externalMessages);
            setExternalMessages(prevMessages => {
                const updatedMessages = [...prevMessages, botMessage];
                console.log("Updated Messages: ", updatedMessages);
                return updatedMessages;
            });
        }
    }, [promptResult2]);

    useEffect(() => {
        if (
            promptResult3 !== '생성을 시작합니다...' &&
            promptResult3 !== '프롬프트 생성기의 Send 버튼을 눌러 생성해주세요.' &&
            promptResult3 !== '생성 중...'
        ) {
            const botMessage = {
                text: promptResult3,
                sender: 'bot',
            };
    
            // 상태 업데이트 전과 후를 로그로 확인
            console.log("Previous externalMessages: ", externalMessages);
            setExternalMessages(prevMessages => {
                const updatedMessages = [...prevMessages, botMessage];
                console.log("Updated Messages: ", updatedMessages);
                return updatedMessages;
            });
        }
    }, [promptResult3]);

    useEffect(() => {
        if (
            promptResult4 !== '생성을 시작합니다...' &&
            promptResult4 !== '프롬프트 생성기의 Send 버튼을 눌러 생성해주세요.' &&
            promptResult4 !== '생성 중...'
        ) {
            const botMessage = {
                text: promptResult4,
                sender: 'bot',
            };
    
            // 상태 업데이트 전과 후를 로그로 확인
            console.log("Previous externalMessages: ", externalMessages);
            setExternalMessages(prevMessages => {
                const updatedMessages = [...prevMessages, botMessage];
                console.log("Updated Messages: ", updatedMessages);
                return updatedMessages;
            });
        }
    }, [promptResult4]);

    useEffect(() => {
        console.log("Updated externalMessages after state update: ", externalMessages);  // 상태가 변경된 후의 값을 출력
    }, [externalMessages]);

    const handleSendButtonClick = async () => {
        if(prompt === "프롬프트 생성 버튼을 눌러 생성해주세요"){
            return;
        }
        setPromptResult('생성을 시작합니다...');
        setPromptResult2('생성을 시작합니다...');
        setPromptResult3('생성을 시작합니다...');
        setPromptResult4('생성을 시작합니다...');

        // 첫 번째 API 호출
        setPromptResult('생성 중...');
        
        const newMessage = {
            text: newPrompt !== '' ? newPrompt : prompt,
            sender: 'user',
            icon: null
        };
        setExternalMessages(prevMessages => [...prevMessages, newMessage]);

        try {
            const response1 = await fetch(`${API_URL}/ask-chatgpt`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Connection': 'keep-alive',
                },
                body: JSON.stringify({ prompt: newPrompt != '' ? newPrompt : prompt }),
                credentials: 'include',
            });
            const data1 = await response1.json();
            console.log('질문1의 답변:', data1);
            const promptResult1 = data1.choices[0].message.content;
            setPromptResult(promptResult1);

            const botMessage1 = {
                text: promptResult1,
                sender: 'bot',
                icon: null
            };
            setExternalMessages(prevMessages => [...prevMessages, botMessage1]);

        } catch (error) {
            console.error('Error sending prompt:', error);
        }
    
        // 두 번째 API 호출
        setPromptResult2('생성 중...');
        const newMessage2 = {
            text: newPrompt2 !== '' ? newPrompt2 : prompt2,
            sender: 'user',
            icon: null
        };
        setExternalMessages(prevMessages => [...prevMessages, newMessage2]);

        try {
            const request2 = newPrompt2 != '' ? newPrompt2 : prompt2 
            const response2 = await fetch(`${API_URL}/ask-chatgpt`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Connection': 'keep-alive',
                },
                body:JSON.stringify({ prompt: request2 + promptFromDB.find(prompt => prompt.promptId === 'table_rule').text}),
                credentials: 'include',
            });
            const data2 = await response2.json();
            console.log('질문2의 답변:', data2);
            const promptResult2 = data2.choices[0].message.content;
            setPromptResult2(promptResult2);

            const botMessage2 = {
                text: promptResult2,
                sender: 'bot',
                icon: null
            };
            setExternalMessages(prevMessages => [...prevMessages, botMessage2]);

        } catch (error) {
            console.error('Error sending prompt:', error);
        }
    
        // 세 번째 API 호출
        setPromptResult3('생성 중...');
        const newMessage3 = {
            text: newPrompt3 !== '' ? newPrompt3 : prompt3,
            sender: 'user',
            icon: null
        };
        setExternalMessages(prevMessages => [...prevMessages, newMessage3]);

        try {
            const request3 = newPrompt3 != '' ? newPrompt3 : prompt3
            const response3 = await fetch(`${API_URL}/ask-chatgpt`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Connection': 'keep-alive',
                },
                // body: JSON.stringify({ prompt: request3 + "(표의 경우 json 형태로 반환하는 것을 명심할 것. 반드시 [,],:를 포함하여 다음의 json 형식으로 반환하시오. [json]:{\"Rows\":[...], \"Columns\":[...], \"Data\":[...]}) "}),
                body:JSON.stringify({ prompt: request3 + promptFromDB.find(prompt => prompt.promptId === 'table_rule').text}),
                credentials: 'include',
            });
            const data3 = await response3.json();
            console.log('질문3의 답변:', data3);
            const promptResult3 = data3.choices[0].message.content;
            setPromptResult3(promptResult3);

            const botMessage3 = {
                text: promptResult3,
                sender: 'bot',
                icon: null
            };
            setExternalMessages(prevMessages => [...prevMessages, botMessage3]);

        } catch (error) {
            console.error('Error sending prompt:', error);
        }
    
        // 네 번째 API 호출
        setPromptResult4('생성 중...');
        const newMessage4 = {
            text: newPrompt4 !== '' ? newPrompt4 : prompt4,
            sender: 'user',
            icon: null
        };
        setExternalMessages(prevMessages => [...prevMessages, newMessage4]);

        try {
            const request4 = newPrompt4 != '' ? newPrompt4 : prompt4
            const response4 = await fetch(`${API_URL}/ask-chatgpt`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Connection': 'keep-alive',
                },
                body:JSON.stringify({ prompt: request4 + promptFromDB.find(prompt => prompt.promptId === 'image_rule').text}),
                // body: JSON.stringify({ prompt: request4 + "(이미지를 생성할 수 없다는 답변을 하지 말고, [image]:{영어로 번역된, 한국 국적으로 설정된 사람의 생성하고자 하는 이미지 내용} 형태의 text를 반환할 것. \n\n무조건 한 명 당 하나의 이미지, [image]:{영어로 번역된, 한국 국적으로 설정된 사람1의 영어 설명}[image]:{영어로 번역된, 한국 국적으로 설정된 사람2의 영어 설명},..."}),
                credentials: 'include',
            });
            const data4 = await response4.json();
            console.log('질문4의 답변:', data4);
            const promptResult4 = data4.choices[0].message.content;
            setPromptResult4(promptResult4);

            const botMessage4 = {
                text: promptResult4,
                sender: 'bot',
                icon: null
            };
            setExternalMessages(prevMessages => [...prevMessages, botMessage4]);
        } catch (error) {
            console.error('Error sending prompt:', error);
        }
    };
    

    const defaultTheme = createTheme();

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Navbar open={navopen} toggleDrawer={toggleDrawer} />
            <LeftNavbar open={navopen} toggleDrawer={toggleDrawer} initialMessages={initialMessages} externalMessages={externalMessages} progress={'insight'} extra={'tamsaminsight'} promptwindow={insightPromptWindowOpen} setPromptWindow={(open) => setInsightPromptWindowOpen(open)}/>
            <Box sx={{ height: "100%", marginTop: 10, marginLeft: 5, marginRight: 5, marginBottom: 5, backgroundColor: 'white' }}>
                <h2>Insight 단계 SOM 특성 추출</h2>
                {/* <table className="TSI_MyTable" style={{width: "100%"}}>
                    <thead>
                        <tr>
                            <th style={{width: "50%"}}>프롬프트</th>
                            
                            <th style={{width: "50%"}}
                                className='column-style'>
                                    ChatGPT 생성 결과</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{newPrompt != '' ? newPrompt :prompt} </td>
                            <td>{renderContent(promptResult)} </td>
                        </tr>
                        <tr>
                            <td>{newPrompt2 != '' ? newPrompt2 :prompt2} </td>
                            <td>{renderContent(promptResult2)} </td>
                        </tr>
                        <tr>
                            <td>{newPrompt3 != '' ? newPrompt3 :prompt3} </td>
                            <td>{renderContent(promptResult3)} </td>
                        </tr>
                        <tr>
                            <td>{newPrompt4 != '' ? newPrompt4 :prompt4} </td>
                            <td>{renderContent(promptResult4)} </td>
                        </tr>
                    </tbody>
                </table> */}

                {/* <div style={containerStyle}>
            <table style={tableStyle} className="custom-table">
                <thead>
                    <tr>
                        <th style={{ width: "10%" }}>목표</th>
                        <th style={{ width: "25%" }}>역할 정의</th>
                        <th style={{ width: "25%" }}>핵심내용 1</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <input
                                style={{ width: '90%', alignItems: 'center' }}
                                type="text"
                                defaultValue="SOM 특성 추출"
                                onChange={(e) => setSelectedGoal(e.target.value)}
                                ref={goalRef}
                            />
                        </td>
                        <td>
                            <input
                                style={{ width: '90%', alignItems: 'center', newline: 'true' }}
                                type="text"
                                defaultValue="스타업을 자문하는 시장 분석 전문가"
                                ref={roleRef}
                                onChange={(e) => setRole(e.target.value)}
                            />
                        </td>
                        <td>
                            <input
                                style={{ width: '90%', alignItems: 'center', newline: true }}
                                type="text"
                                value={keywords != "null" ? keywords : '로딩 중...'}
                                onChange={(e) => setKeywords(e.target.value)}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <Button variant="contained" color="primary" onClick={handleButtonClick}>프롬프트 생성</Button>
        </div>

        <div style={containerStyle}>
            <table style={tableStyle} className="custom-table">
                <thead>
                    <tr>
                        <th style={{ width: "50%" }}>추천 프롬프트</th>
                        <th style={{ width: "50%" }}>수정 프롬프트</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{prompt}</td>
                        <td>
                            <textarea
                                style={{ width: "100%", height: "100%" }}
                                onChange={(e) => setNewPrompt(e.target.value)}
                            >
                                {newPrompt}
                            </textarea>
                        </td>
                    </tr>
                    <tr>
                        <td>{prompt2}</td>
                        <td>
                            <textarea
                                style={{ width: "100%", height: "100%" }}
                                onChange={(e) => setNewPrompt2(e.target.value)}
                            >
                                {newPrompt2}
                            </textarea>
                        </td>
                    </tr>
                    <tr>
                        <td>{prompt3}</td>
                        <td>
                            <textarea
                                style={{ width: "100%", height: "100%" }}
                                onChange={(e) => setNewPrompt3(e.target.value)}
                            >
                                {newPrompt3}
                            </textarea>
                        </td>
                    </tr>
                    <tr>
                        <td>{prompt4}</td>
                        <td>
                            <textarea
                                style={{ width: "100%", height: "100%" }}
                                onChange={(e) => setNewPrompt4(e.target.value)}
                            >
                                {newPrompt4}
                            </textarea>
                        </td>
                    </tr>
                </tbody>
            </table>
            <Button variant="contained" color="primary" onClick={handleSendButtonClick}>Send</Button>
        </div> */}
        
        {insightPromptWindowOpen && (
                    <Window
                        id="react-window2"
                        height={window.innerHeight / 2}
                        width={window.innerWidth / 2.4}
                        left={window.innerWidth / 4}
                        top={window.innerHeight / 2.6}
                        resizable={true}
                        titleBar={{
                            // icon: "R",
                            title: "프롬프트 생성기",
                            buttons: { minimize: true, maximize: true, close: () => setInsightPromptWindowOpen(!insightPromptWindowOpen) }
                        }}
                        style={{ backgroundColor: 'white', height: '100%' }}
                    >
                        <div style={containerStyle}>
                            <table style={tableStyle} className="custom-table">
                                <thead>
                                    <tr>
                                        <th style={{width:"10%"}}>목표</th>
                                        <th style={{width:"25%"}}>역할 정의</th>
                                        <th style={{width:"25%"}}>핵심내용 1</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                        <input
                                                style={{ width: '90%', alignItems: 'center' }}
                                                type="text"
                                                defaultValue="SOM 특성 추출"
                                                onChange={(e) => setSelectedGoal(e.target.value)}
                                                ref={goalRef}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                style={{ width: '90%', alignItems: 'center', newline: 'true' }}
                                                type="text"
                                                defaultValue="스타업을 자문하는 시장 분석 전문가"
                                                ref={roleRef}
                                                onChange={(e) => setRole(e.target.value)}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                style={{ width: '90%', alignItems: 'center' , newline: true }}
                                                type="text"
                                                value={keywords != "null" ? keywords : '로딩 중...'}
                                                onChange={(e) => setKeywords(e.target.value)}
                                            />
                                        </td>
                                        
                                    </tr>
                                </tbody>
                            </table>
                            <button onClick={handleButtonClick}>프롬프트 생성</button>
                        </div>

                        <div style={containerStyle}>
                            <table style={tableStyle} className="custom-table">
                                <thead>
                                    <tr>
                                        <th style={{width:"50%"}}>추천 프롬프트</th>
                                        <th style={{width:"50%"}}>수정 프롬프트</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{prompt}</td>
                                        <td>
                                            <textarea 
                                            style={{width:"100%", height:"100%"}}
                                            onChange={(e) => setNewPrompt(e.target.value)}
                                            >
                                            {newPrompt}
                                            </textarea>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{prompt2}</td>
                                        <td>
                                            <textarea 
                                            style={{width:"100%", height:"100%"}}
                                            onChange={(e) => setNewPrompt2(e.target.value)}
                                            >
                                            {newPrompt2}
                                            </textarea>
                                            </td>
                                    </tr>
                                    <tr>
                                        <td>{prompt3}</td>
                                        <td>
                                            <textarea
                                            style={{width:"100%", height:"100%"}}
                                            onChange={(e) => setNewPrompt3(e.target.value)}
                                            >
                                            {newPrompt3}
                                            </textarea>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{prompt4}</td>
                                        <td>
                                            <textarea
                                            style={{width:"100%", height:"100%"}}
                                            onChange={(e) => setNewPrompt4(e.target.value)}
                                            >
                                            {newPrompt4}
                                            </textarea>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <button onClick={handleSendButtonClick}>Send</button>
                        </div>
                    </Window>
                )}   

            <CreatePersona></CreatePersona>
            {/* <StrategyEvaluatePersona></StrategyEvaluatePersona> */}

            </Box>
        </Box>

    );
}