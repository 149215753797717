import * as React from 'react';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Navbar from './Navbar/Navbar';
import LeftNavbar from './Navbar/LeftNavbar';
import CustomerNavbar from '../Customer/Navbar/LeftNavbar';
import 'react-data-grid/lib/styles.css';
import { useEffect, useState, useRef } from 'react';
import '../../styles/TamSamInsight.css';
import { useNavigate } from 'react-router-dom';
import CreatePersona from './InsightCreatePersona';
import Window from '../Customer/Window/Window';
import StrategyChat from '../Customer/StrategyChat';
const API_URL = process.env.REACT_APP_API_ORIGIN;

export default function StrategySom() {
    const navigate = useNavigate();
    const [navopen, setNavOpen] = useState(true);

    const [initialMessages, setInitialMessages] = useState([]);
    const [externalMessages, setExternalMessages] = useState([]);

    const toggleDrawer = () => {
        setNavOpen(!navopen);
    };

    

    const defaultTheme = createTheme();

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Navbar open={navopen} toggleDrawer={toggleDrawer} />
            {localStorage.getItem("userType") === "consultant" ? <LeftNavbar open={navopen} toggleDrawer={toggleDrawer} initialMessages={initialMessages} externalMessages={externalMessages} progress={'strategy'}/>
            : <CustomerNavbar open={navopen} toggleDrawer={toggleDrawer}/>}
            <Box sx={{ height: "100%", marginTop: 10, marginLeft: 5, marginRight: 5, marginBottom: 5, backgroundColor: 'white' }}>
                
            <CreatePersona progress={'strategy'}/>
            {/* <StrategyEvaluatePersona></StrategyEvaluatePersona> */}

            {localStorage.getItem('userType') === 'client' && (
                <Window
                id="react-window3"
                height={320}
                width={630}
                left={window.innerWidth / 5.5}
                top={window.innerHeight / 3.2}
                resizable={true}
                titleBar={{
                    title: 'Chat',
                    buttons: { minimize: true, maximize: true, close: false },
                }}
                >
                <StrategyChat 
                initialMessage={"SOM 시장 목표 고객에 대한 페르소나와 특징에 대한 비교 자료입니다. 목표 고객 설정을 위해서 평가(5점 척도) 및 의견을 주십시오."}
                />
                </Window>
            )}
            </Box>
        </Box>

    );
}