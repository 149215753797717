import React, { useState, useEffect } from 'react';
import { Box, CssBaseline, ThemeProvider, Typography, Divider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Navbar from './Navbar/Navbar';
import LeftNavbar from './Navbar/LeftNavbar';
import Toolbar from '@mui/material/Toolbar';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';

const FactDetail = () => {
    const [open, setOpen] = useState(true);
    const navigate = useNavigate();

    const toggleDrawer = () => {
        setOpen(!open);
    };


    const defaultTheme = createTheme();

    return (
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Navbar open={open} toggleDrawer={toggleDrawer} />
                <LeftNavbar open={open} toggleDrawer={toggleDrawer} />
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        height: '100vh',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3} style={{ height: '100%' }}>
                            {/* 상단에 FACT 단계 제목 */}
                            <Grid item xs={12}>
                                <Typography variant="h5" align="left">
                                    FACT 단계
                                </Typography>
                                <Divider />
                            </Grid>

                            {/* 가로로 반으로 나뉘어진 섹션 */}
                            <Grid item xs={6}>
                                {/* 첫 번째 섹션의 텍스트 문단 */}
                                <Typography variant="body1" align="left" style={{ marginBottom: '48px' }}>
                                    사업아이디어 혹은 모델에 대한 내용을 이해하고<br /> 사업이 어떤 유형인지를 진단합니다.
                                </Typography>
                                <Typography variant="body1" align="left" style={{ marginBottom: '48px' }}>
                                    사업의 TAM / SAM 시장을 찾아내고 <br />전반적인 트렌드, 고객특성, 경쟁현황 등을 파악합니다.
                                </Typography>
                                <Typography variant="body1" align="left" style={{ marginBottom: '48px' }}>
                                    이를 통해서 창업가는 사업의 가능성을 판단하고,<br /> Go / Stop 등을 결정할 수 있습니다.
                                </Typography>
                            </Grid>

                            <Grid item xs={6}>
                                {/* 두 번째 섹션의 텍스트 문단 */}
                                <Typography variant="body1" align="left" style={{ marginBottom: '48px' }}>
                                    <strong>GOAL</strong><br />
                                    ● 사업아이디어의 사업 모델유형 및 목표시장을 진단<br />
                                    ● TAM / SAM 시장을 추정<br />
                                </Typography>
                                <Typography variant="body1" align="left" style={{ marginBottom: '48px' }}>
                                    <strong>STEPS</strong><br />
                                    ● 사업 / 시장 / 고객 / 오퍼링 유형 진단<br />
                                    ● 사업아이디어 진단<br />
                                    ● SAM 시장 규모 및 성장세 추론<br />
                                </Typography>
                                <Typography variant="body1" align="left" style={{ marginBottom: '48px' }}>
                                    <strong>OUTCOMES</strong><br />
                                    ● TAM / SAM 시장 정의<br />
                                    ● SAM 시장 트렌드 / 경쟁구도 / 고객특성<br />
                                    ● SAM 시장 규모 및 성장세 추론<br />
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* 버튼 추가 */}
                        <Grid item xs={12} style={{ marginTop: '24px', textAlign: 'center' }}>
                            <button onClick={() => navigate('/Backinfo')} style={{ fontSize: '12px', padding: '8px 36px' }}>
                                다 음
                            </button>
                        </Grid>

                        {/* 하단 설명 */}
                        <Grid item xs={12} style={{ marginTop: '48px' }}>
                            <Typography variant="body2" align="left">
                                <strong>TAM (Total Addressable Market):</strong> 전체 시장 규모로, 해당 제품이나 서비스로 이루어진 최대 매출이 가능한 규모입니다.
                            </Typography>
                            <Typography variant="body2" align="left" style={{ marginTop: '24px' }}>
                                <strong>SAM (Serviceable Available Market):</strong> 현재 기업이나 제품이나 서비스가 더할 수 있는 시장으로, 현재 제품이나 서비스로 이루어진 매출이 가능한 규모입니다.
                            </Typography>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default FactDetail;
