import React from "react";

const StrategyChat =({ initialMessage }) => {

  return (
    <div className="app" style={{ fontFamily: '"Pretendard-Regular", Arial, Roboto' }}>
      <div className="chat-box" >
        <h1 style={{marginLeft:'20px'}}>🤖 Chat Bot</h1>
        <div className="messages-list">
          <div className="ai-message">
            <p>
              <b>Step Bot</b>: {initialMessage}
            </p>
          </div>
        </div>
        <div className="input-box">
          <input 
            type="text" 
            placeholder="고객 의견란에 직접 입력하여 주십시오." 
            disabled 
            style={{
              width: "calc(100% - 50px)",
              padding: "10px",
              marginTop: "10px",
              marginLeft : "10px",
              borderRadius: "5px",
              border: "1px solid #ccc"
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default StrategyChat;
