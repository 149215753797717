import React, { useState, useEffect } from 'react';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Navbar from './Navbar/Navbar';
import LeftNavbar from './Navbar/LeftNavbar';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Fab from '@mui/material/Fab';
import RightArrow from '@mui/icons-material/KeyboardArrowRight';
import LeftArrow from '@mui/icons-material/KeyboardArrowLeft'

const API_URL = process.env.REACT_APP_API_ORIGIN;

const ConsultantFact2 = () => {
    const clientId = localStorage.getItem('clientId');
    const [open, setOpen] = useState(true);
    const [uid, setUid] = useState('');
    const [data, setData] = useState([]);
    const [btype, setBtype] = useState('null');
    const [offeringValues, setOfferingValues] = useState([]);
    const navigate = useNavigate();

    const toggleDrawer = () => {
        setOpen(!open);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const uidResponse = await Axios.get(`${API_URL}/auth/getUidByUserId/userId=${clientId}`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                setUid(uidResponse.data);

                const btyperesponse = await Axios.get(`${API_URL}/auth/getBusinessType`, {
                    params: {
                        uid: uidResponse.data,
                    },
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const btypeValue = btyperesponse.data.split('_')[1];
                if(btypeValue==='기존시장')
                    setBtype('기존');
                else
                    setBtype(btypeValue);

                const otyperesponse = await Axios.get(`${API_URL}/auth/getOfferingType`, {
                    params: {
                        uid: uidResponse.data
                    },
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const otypedata = otyperesponse.data.split(',');
                setOfferingValues(otypedata);
                console.log(otypedata);

                const [response, answerResponse] = await Promise.all([
                    fetch(`${API_URL}/api/questions`),
                    fetch(`${API_URL}/api/answers/user/uid=${uidResponse.data}`),
                ]);

                let question = await response.json();
                let savedAnswer = await answerResponse.json();

                question = question.slice(0, 10).map(({ questionId, question, questionClass }) => ({
                    questionId,
                    question,
                    questionClass,
                }));

                savedAnswer = savedAnswer.slice(0, 10).map(({ questionId, answerText }) => ({
                    questionId,
                    answerText,
                }));

                const tempdata = question.map(q => {
                    const answer = savedAnswer.find(a => a.questionId === q.questionId);
                    return {
                        ...q,
                        answerText: answer ? answer.answerText : null,
                    };
                });

                const groupedData = [];
                for (let i = 0; i < tempdata.length; i += 2) {
                    groupedData.push(tempdata.slice(i, i + 2));
                }

                setData(groupedData);

            } catch (error) {
                console.error('Error submitting form:', error);
            }
        };

        fetchData();
    }, [clientId]);


    return (
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Navbar open={open} toggleDrawer={toggleDrawer} />
                <LeftNavbar open={open} toggleDrawer={toggleDrawer} progress={'fact'}/>
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <h2>고객 시장 유형 진단 결과</h2>
                        <hr style={{ width: '100%', marginBottom: '20px' }} /> {/* 가로줄 추가 */}
                        <div className="table-container">
                            <table style={{ marginBottom: "20px" }}>
                                {/* <thead> */}
                                <tbody>

                                    <tr>
                                        <th colSpan="2" rowSpan="3" className="tableHeader">시장유형진단</th>
                                        <th colSpan="2" className="tableHeader">고객층</th>
                                    </tr>
                                    <tr>
                                        <th className="tableHeader">신규</th>
                                        <th className="tableHeader">기존</th>
                                    </tr>
                                    <tr>
                                        <td className="tableCell">경쟁사들이 목표로 하지 않는 새로운 고객층</td>
                                        <td className="tableCell">경쟁사들도 목표로 하고 있는 고객층</td>
                                    </tr>
                                    {/* </thead> */}
                                    {/* <tbody> */}
                                    <tr className="tableRow">
                                        <th rowSpan="6" className="tableHeader">제품/서비스</th>
                                        <th className="tableHeader">신규</th>
                                        <td className="tableCell">
                                            <input
                                                type="checkbox"
                                                checked={btype === "블루오션"}
                                                readOnly
                                            />
                                        </td>
                                        <td className="tableCell">
                                            <input
                                                type="checkbox"
                                                checked={btype === "신제품"}
                                                readOnly
                                            />
                                        </td>
                                    </tr>
                                    <tr className="tableRow">
                                        <td className="tableCell">기존의 오퍼링을 새롭게 혁신한 경우</td>
                                        <th className="tableHeader">블루오션 시장</th>
                                        <th className="tableHeader">신제품 시장</th>
                                    </tr>
                                    <tr className="tableRow">
                                        <td className="tableCell">지금까지 세상에 없는 완전히 새로운 오퍼링</td>
                                        <td className="tableCell">
                                            <ul>
                                                <li>기존시장에 존재하는 오퍼링을 혁신적인 기술로 업그레이드하거나 또는 혁신적인 비즈니스 모델을 통해 새로운 고객층에게 접근</li>
                                                <li>이를 통해 기존의 경쟁자들이 미처 해결하지 못하거나 보지 못하는 기회를 포착한 경우</li>
                                                <li>기존 경쟁자들과 직접적 경쟁 발생하지 않음</li>
                                                <li>지금까지 세상에 존재하지 않았던 전혀 새로운 오퍼링으로 고객을 개척하는 경우</li>
                                            </ul>
                                        </td>
                                        <td className="tableCell">
                                            <ul>
                                                <li>기존 고객층에게 제공되었던 오퍼링을 혁신적인 기술로 업그레이드하거나 또는 혁신적인 비즈니스 모델을 통해 접근하여 기존고객에게 접근하는 경우</li>
                                                <li>그러나 이로 인해 기존 경쟁자들과 직접적 경쟁 발생함</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr className="tableRow">
                                        <th className="tableHeader">기존</th>
                                        <td className="tableCell">
                                            <input
                                                type="checkbox"
                                                checked={btype === "신규고객"}
                                                readOnly
                                            />
                                        </td>
                                        <td className="tableCell">
                                            <input
                                                type="checkbox"
                                                checked={btype === "기존"}
                                                readOnly
                                            />
                                        </td>
                                    </tr>
                                    <tr className="tableRow">
                                        <td className="tableCell">경쟁사들과 크게 차별화되지 않은 오퍼링</td>
                                        <th className="tableHeader">신규 고객 시장</th>
                                        <th className="tableHeader">기존 시장</th>
                                    </tr>
                                    <tr className="tableRow">
                                        <td className="tableCell">기존 오퍼링을 혁신 수준이 아닌 개선 정도 수준으로 업그레이드 한 경우</td>
                                        <td className="tableCell">
                                            <ul>
                                                <li>기존 시장에서 거래된던 오퍼링을 가지고 새로운 고객층 개발</li>
                                                <li>고객층은 새로우나 오퍼링은 기존 경쟁자와 크게 차별화되지 않음</li>
                                                <li>해외에서 성공한 오퍼링이나 비즈니스 모델을 복제하여 국내로 들여오거나 그 반대 경우</li>
                                            </ul>
                                        </td>
                                        <td className="tableCell">
                                            <ul>
                                                <li>현재 존재하는 시장 - 이미 경쟁자와 고객이 존재하는 시장에 기존 경쟁자와 크게 차별화되지 않은 오퍼링으로 진출하는 경우</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    {/* </thead> */}

                                </tbody>
                            </table>
                        </div>
                        <table style={{ marginBottom: "20px", width: "100%" }}>
                            <tbody>
                                <tr>
                                    <td rowSpan={5} style={{ width: "20%" ,fontSize:'16px'}}>{btype}시장</td>
                                    <td rowSpan={2} style={{ width: "30%" }}>고객</td>
                                    <td style={{ display: 'flex', alignItems: 'center', width: "10%" }}>
                                        <input
                                            type="checkbox"
                                            checked={offeringValues.includes("B2B")}
                                            style={{ marginRight: '10px' }}
                                            readOnly
                                        />
                                        B2B(G)
                                    </td>
                                    <td style={{ width: "40%" }}>목표 고객은 기업 혹은 정부기관</td>
                                </tr>
                                <tr>
                                    <td style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            type="checkbox"
                                            checked={offeringValues.includes("B2C")}
                                            style={{ marginRight: '10px' }}
                                            readOnly
                                        />
                                        B2C
                                    </td>
                                    <td>목표 고객이 다수의 개인</td>
                                </tr>
                                <tr>
                                    <td rowSpan={3}>제품/서비스</td>
                                    <td style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            type="checkbox"
                                            checked={offeringValues.includes("하드웨어")}
                                            style={{ marginRight: '10px' }}
                                            readOnly
                                        />
                                        하드웨어
                                    </td>
                                    <td>제공하는 오퍼링이 유형의 하드웨어</td>
                                </tr>
                                <tr>
                                    <td style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            type="checkbox"
                                            checked={offeringValues.includes("소프트웨어")}
                                            style={{ marginRight: '10px' }}
                                            readOnly
                                        />
                                        소프트웨어
                                    </td>
                                    <td>제공하는 오퍼링이 유형의 소프트웨어</td>
                                </tr>
                                <tr>
                                    <td style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            type="checkbox"
                                            checked={offeringValues.includes("서비스")}
                                            style={{ marginRight: '10px' }}
                                            readOnly
                                        />
                                        서비스
                                    </td>
                                    <td>제공하는 오퍼링이 무형의 서비스</td>
                                </tr>
                            </tbody>
                        </table>
                    </Container>
                </Box>
                <Fab color="secondary"
                    varient="extended"
                    onClick={() => navigate('/changequestion')}
                    sx={{
                        position: 'fixed',
                        width: 100,
                        bottom: 30,
                        right: 30,
                        borderRadius: 5, // 직사각형으로 만들기 위해 borderRadius 값을 조절합니다.
                        boxShadow: '0, 0, 0, 0.5',
                    }} >
                    <span>다음</span>
                    <RightArrow />
                </Fab>
                <Fab color="secondary"
                    varient="extended"
                    onClick={() => navigate('/consultantfact1')}
                    sx={{
                        position: 'fixed',
                        width: 100,
                        bottom: 30,
                        right: 150,
                        borderRadius: 5, // 직사각형으로 만들기 위해 borderRadius 값을 조절합니다.
                        boxShadow: '0, 0, 0, 0.5',
                    }} >
                    <span>이전</span>
                    <LeftArrow />
                </Fab>
            </Box>
    );
};

export default ConsultantFact2;
